import {html} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import deepEqual from 'deep-equal';

import {getValueForLanguage} from '../../../util/getValueForLanguage';

import style from '../../../../../scss/multilingualInput.scss';
import {FormElement} from './formElement';
import {deepClone} from '../../../util/deepClone';
import {selectCurrentProfileDefaultLanguage} from '../../../selectors';

@customElement('arc-multilingual-input')
export class MultilingualInput extends FormElement {

	static properties = {
		value: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)}, // initial value
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},
		placeholder: {type: String},
		title: {type: String},
		currentLanguage: {type: String},
		selectedLanguage: {type: String},
		multiline: {type: Boolean},
		edit: {type: Boolean},
		required: {type: Boolean},

		index: {type: Number}, // index of the element when part of repeat
		type: {type: String} // type of data edited by form element
	};

	static styles = [...FormElement.styles, style];

	initialValue = [{lang: 'en', value: ''}, {lang: 'de', value: ''}];

	constructor() {
		super();
		this.languages = ['en', 'de'];
	}

	stateChanged(state) {
		super.stateChanged(state);
		this.state = state;
	}
	connectedCallback() {
		super.connectedCallback();
		this.currentLanguage = selectCurrentProfileDefaultLanguage(this.state);
		this.selectedLanguage = '' + selectCurrentProfileDefaultLanguage(this.state) ?? this.languages[0];
	}

	handleValueChange(e) {
		const input = e.target;
		const value = input.value;
		let newValue = [];
		this.languages.forEach((lang) => {
			const oldValue = this.value.find((v) => v.lang === lang) ?? '';
			newValue.push({
				lang: lang,
				value: lang === this.selectedLanguage ? value : (oldValue ? oldValue.value : '')
			});
		});
		// filter out items with empty string value
		newValue = newValue.filter((v) => v.value !== '');
		this.dispatchValueChange(newValue);
	}

	handleLanguageChange(e) {
		this.selectedLanguage = e.target.value;
	}

	render() {

		if (!this.edit) {
			return html`
				<div>
					<div class="textInputInfo">
						<div class="title">${this.title}</div>
					</div>
					<p class="value">${getValueForLanguage(this.value, this.currentLanguage, false)}</p>
				</div>
			`;
		}
		return html`
			<div class="multilingual-input ${classMap({errors: this.hasErrors(), changed: this.inputHasChanged(), required: this.required})}">
				<div class="textInputInfo">
					<div class="title">
						${this.title}
						${when(this.required, () => html`<span class="requiredMark">*</span>`)}
					</div>
					${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
				</div>
				<div class="language-switcher">
					<select class="language-select" @change=${this.handleLanguageChange}>
						${this.languages.map(lang => html`
							<option value="${lang}" ?selected=${this.selectedLanguage === lang}>${lang}</option>`)}
					</select>
				</div>
				<div class="input-wrapper">
					${when(this.multiline,
						() => html`
							<textarea
								placeholder="${this.placeholder}"
								@input=${this.handleValueChange}
								.value=${getValueForLanguage(this.value, this.selectedLanguage, false)}
						/></textarea>`,
						() => html`
							<input
								placeholder="${this.placeholder}"
								@input=${this.handleValueChange}
								.value=${getValueForLanguage(this.value, this.selectedLanguage, false)}
							/>`
					)}
				</div>
				<arc-errors .errors=${this.errors}></arc-errors>
			</div>
		`;
	}


}
