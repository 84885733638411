import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import style from '../../../../../scss/tags.scss';
import {FormElement} from './formElement';

@customElement('arc-tags')
export class TextInput extends FormElement {

	static properties = {
		...FormElement.properties
	};

	static styles = [...FormElement.styles, style];

	initialValue = [];

	handleValueChange(e) {
		const tags =  e.target.value.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
		this.dispatchValueChange(tags);
	}
	removeTag(tag) {
		const tags = this.value.filter(t => t !== tag);
		this.dispatchValueChange(tags);
	}

	render() {
		if (!this.edit) {
			return html`
				<div>
					<div class="textInputInfo">
						<div class="title">${this.title}</div>
					</div>
					<p class="value">${this.value ?? ''}</p>
				</div>
			`;
		}
		return html`
			<div class="${classMap({errors: this.hasErrors(), changed: this.inputHasChanged()})}">
				<div class="textInputInfo">
					<div class="title">
						${this.title}
						${when(this.required, () => html`<span class="requiredMark">*</span>`)}
					</div>
					${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
				</div>
				<div class="tags">
					${this.value.map(tag => html`
						<div class="tag" @click=${() => this.removeTag(tag)}>${tag}</div>
					`)}
				</div>
				<input
					.required="${this.required}"
					placeholder="${this.placeholder}"
					.value=${this.value.join(', ') ?? ''}
					@input=${this.handleValueChange}
				/>
				<arc-errors .errors=${this.errors}></arc-errors>
			</div>
    `;
	}
}
