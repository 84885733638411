
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    import {str} from '@lit/localize';

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's032cd9f1245d3645': `Maße`,
's0379fc73608ab971': `Fertig`,
's03848d257276adc8': `genaues Datum`,
's03aa3cfb08dff960': `Tags`,
's0491d2d22458cf73': `Maximale Anzahl an Videos überschritten`,
's06b291180b5c2ef2': `Dieses Kunstwerk entfernen, wird es direkt aus Ihrer Liste von Kunstwerken ausblenden.`,
's071d99b3ab93bbcb': `Ist Ensemble/Künstlergruppe`,
's087eb6b22eae8f9c': `Kunstwerk hinzufügen`,
's08c43a07b566d980': `hoch`,
's08d844177965c733': `Filter zurücksetzen`,
's0c599150290fb992': `Kein Text`,
's0c6538ad32621450': str`Kunstwerk "${0}" entfernen, wird es direkt aus Ihrer Liste von
					Kunstwerken ausblenden.
				`,
's0f0202c2780ce439': `Untertitel`,
's0f2cb376eeeaa423': `Jahr (Start)`,
's0f8a40190d30e7e3': `Lebenslauf bearbeiten`,
's1219d97b49650c05': `Sichtbarkeit`,
's13e17f38a55b94ca': `Information Hinzufügen`,
's165d3919b108ec51': `Biografie`,
's1829ba489430c269': `Kunstfonds Inventarnummer`,
's1946c13a177a90a1': `Inventarnummern`,
's1add68562a420778': `Detaillierte Beschreibung der Materialien`,
's1b5bba3d9041629a': `Einstellungen speichern`,
's1c1134cbda4fe1ee': `Falsches Format`,
's1e61bece71b51008': `Gelöschte Einträge`,
's21900f027f2d7cfa': `Durchmesser`,
's219c37011b334110': `Medien hinzufügen`,
's2468b69d10791c82': `Stadt`,
's24d03b95f001a869': `Kunstwerk entfernen`,
's26f91b6796e33111': `Wiederherstellen`,
's292da8ff092860a5': `Status wählen ...`,
's2a75c946ef4dcab7': `Verschiedenes`,
's2a8434b16dc214a3': `Ersetzen`,
's2c57913ffd36d298': `Vorname`,
's30c9440f8f06fc3c': `Maximale Anzahl an Dateien überschritten`,
's31b9c53d98feb660': `Login Code Anfordern`,
's31d694b87ea70f4d': `Falsches Datumsformat`,
's3231d0dc6fe30d4a': `Tiefe`,
's32ac2b6848504809': `Nationalität`,
's32b3ac51a9c31f47': `Zum Profil wechseln`,
's332a5235948c1a1d': `Ich akzeptiere die Datenschutzbestimmungen.`,
's336c8e1678a8ff80': `Löschen`,
's3375a4920652b810': `Serien`,
's33b30147022d7f8c': `Token Eingeben`,
's345fbf4889ae0c7f': `Tas Kunstwerk enthält untergeordnete Einträge, bitte löschen Sie diese zuerst.`,
's34aa94e923bb3d75': `Eingabe nicht Teil der Menge`,
's34d7c565f291023e': `Unverkäuflich`,
's35f1f5ccf4706d78': `Sichtbarkeit wählen ...`,
's37083e19c2f9c755': `Nr.`,
's383f38fbc7a28b77': `Datei zu groß`,
's38be98a354b0a135': `Signiert`,
's3af9cf9709f61b9a': `Keine gelöschten Einträge gefunden.`,
's3cf89cb47fdde7e9': `Link`,
's3dc7f921134f8d35': `Im Werksverzeichniss des Kunstfonds können Sie Ihr Künstlerprofil, Ihren Lebenslauf und
					Ihre Kunstwerke so einfach oder detailliert bearbeiten, wie Sie möchten.
				`,
's402ed9d74111a751': `Erstellungsdatum (Ende)`,
's41f7dca46d3f1b4a': `Wert`,
's43e372d3fcabbd0a': `Basisattribute`,
's477bc7abb235cce6': `Filter ausblenden`,
's47a2a1f01af73025': `Todestag`,
's4880dc3125543292': `Erwähnungen`,
's49c6478ea452050b': `Lebenslauf konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's4b8475efa7c73228': `Inventarnummer`,
's4caed5b7a7e5d89b': `Englisch`,
's4cd84215487b493a': `Diagonale`,
's4fcd634b4ebc761b': `Abschnitt hinzufügen`,
's5096bdb5f882fb29': `Materialien`,
's517acbf1f96324f9': `Gewicht`,
's523f5d97bb322419': `Konto Einstellungen`,
's552ca8336fe964d0': `Ausstellungschronik`,
's5540c926a41a6be9': `Profil hinzufügen`,
's5590dbf7e425789d': `Bezeichnung`,
's58d4bced0b668ae6': `Keine Zahl`,
's5a41c3a63f1c18af': `Profil Enstellungen`,
's5d7d95ba0d2c4869': `Das Formular konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's5d929ff1619ac0c9': `Suche`,
's5e8250fb85d64c23': `Schließen`,
's5e9f4a47a1f2cd25': `Kunstwerk gespeichert`,
's63d894b1ddb06289': `Beschreibung`,
's63e457bc703a3030': `E-Mail`,
's63e71d20d1eaca93': `Deutsch`,
's64a6dcf9cd66376f': `Das Formular konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's64ef2a6c2dd1d3d1': `Bearbeiten`,
's65bbdef13ec0b7ff': `Titel eingeben`,
's67749057edb2586b': `Abmelden`,
's6801787e32a10951': `Im Werksverzeichniss des Kunstfonds können Sie Ihr Künstlerprofil, Ihren Lebenslauf und
					Ihre Kunstwerke so einfach oder detailliert bearbeiten, wie Sie möchten.
				`,
's69201fa8972b3e66': `Das Kunstwerk enthielt keine Daten - es wurde permanent gelöscht.`,
's6cedf419e20c815a': `Telefon`,
's6d8c02aee480af7a': `Menü`,
's6e0f65a28704ec9f': `Name des Abschnitts`,
's72f2a02e2a5a4ee3': `Medien`,
's73cdb5bba903a27f': `Ersetzen`,
's77585085193f50c2': `Falsches Linkformat`,
's7858dcc121b436fc': `Das Profil konnte nicht gespeichert werden. Bitte beheben Sie die Fehler.`,
's7c34d7a176d95aa0': `Jahr (Ende)`,
's7d38fc29b20f951b': `Grundlegende Informationen`,
's7d8d449c7a0483c8': `Keine Medien vorhanden`,
's7f1c2ac56a84f421': `Willkommen zu der Werkverzeichnung der Stiftung Kunstfonds`,
's804ca9ce39d5c56b': `Code Anfordern`,
's814ca8490eb230c0': `Kunstwerk hinzugefügt`,
's821ef36d63a8a111': `Zuletzt geändert`,
's82665b2ffabc9c0a': `Website`,
's83f26d02dd7aa681': `sichtbar`,
's846b3790b6e25c1c': `Konto`,
's855aaab4663315b4': `Kunstwerke`,
's8641b6c3688a0cec': `Profil bearbeiten`,
's8677baad3bf6b295': `Lebenslauf speichern`,
's87bae2710b2492c3': `Kontakt`,
's8a6c0085cb6c64a0': `Geschlecht`,
's8ac6ca24f22e5e3f': `Verkauft`,
's927497135cdabe78': `Unbenannt`,
's92921878e886e36d': `Zeitraum der Zusammenarbeit`,
's92fef0e2fba7468c': `Spracheinstellungen`,
's931ba7d0f5588be8': `Standard Sprache`,
's93c046d8c5bb2047': `Geburtstag`,
's9502410942da6b88': `Lebenslauf Eintrag`,
's95a75a2392af5780': `Nachname`,
's99b2b8a3d8ae8081': `Kunstwerk öffentlich anzeigen`,
's99f110d27e30b289': `Titel`,
's9b1394a1375404d4': `Ist Nachlass?`,
's9cafd50042887a6c': `Material wählen ...`,
's9cf7402fe920f7d1': `Land`,
's9d0a3b8de87941d8': `Kaufpreis`,
's9d8b8aa2b404c2c8': `Einstellungen`,
's9e92503bfc80d2d6': `Art des Kunstwerks`,
'sa080ed80aad67329': `Datei zu lang`,
'sa1dc38bb8e8339f3': `Künstlername`,
'sa2686cd9fcc26088': `Kunstwerk speichern`,
'sa5d2f9afa34fd3c3': `Token anfragen`,
'sa65a95e0199f2647': `Fehlerhafte Eingabe`,
'sa6f2645578b2d2bc': `Jahr`,
'sa7e28eff7384ceee': `Als wichtig markieren`,
'sa87645df04d5260a': `Das Kunstwerk wurde nach Gelöschte Einträge verschoben. Es wird in 7 Tagen permant gelöscht.
				`,
'sad3e3c8146fc920f': `Status`,
'sae239213b7c70376': `Zustand`,
'sae42bf06e5c4bbe5': `E-Mail eingeben`,
'saffa23915bede5a1': `PLZ`,
'sb061ff5a347a296e': `Profil`,
'sb0f8ba7eed402d21': `Lebenslauf gespeichert`,
'sb19c9290038389c0': `Material`,
'sb28076159e581755': `Medien bearbeiten`,
'sb9cdfae56d72cfe2': `Provenienz`,
'sbc2cea55676b3aca': `Geben Sie die E-Mail Adresse ein, an den der Login Code gesendet werden soll.`,
'sbec9dc4ffcfd8ecb': `Typ wählen ...`,
'sc047622d98fe58c5': `Werksverzeichnis`,
'sc14632800a845f4a': `Tags auswählen`,
'sc2c901ea4b99a96a': `Nachlass`,
'sc2d94a6963318685': `Profil bearbeiten`,
'sc3693cd85fbd07d3': `Kein Boolean`,
'sc4bbedc293a299bb': `Datei nicht vorhanden`,
'sc592307ea80f16b9': `Unbekannt`,
'sc622b6f2e8694aac': `Medien speichern`,
'sc6a7cb64b423cffa': `Verliehen`,
'sc7bcd1669fd8e336': `Bestandteil hinzufügen`,
'sc8da3cc71de63832': `Anmelden`,
'sc93a9aa3e5bcbf5d': `Profil speichern`,
'sca08a0a32920416b': `Keine Kunstwerke gefunden.`,
'sca974356724f8230': `Anmelden`,
'scbbbf3cce4237b03': `Pflichtfeld`,
'scc316262f13a1da8': `Keine Zahl`,
'scd6ac002544eb0d6': `Wert Kategorie`,
'scf8fcc133a2f8f5b': `Profilname eingeben`,
'scfa9e1e719a45ae3': `Maximale Länge Überschritten`,
'scfce1140fb4a263f': `Profil auswählen`,
'sd1f44f1a8bc20e67': `E-Mail`,
'sd2223afb7d6b100d': `Typ`,
'sd24d2099f6f4a88b': `Lebenslauf`,
'sd33f062c19e2e331': `Name`,
'sd55bb7d228718163': `keine E-Mail Adresse`,
'sd80496e9c65f1366': `Anmerkungen`,
'sdc04126761bb7455': `runter`,
'sdc673e73b5c13aea': `Löschen`,
'sdcb9b5b53f8c8fc7': `Entfernte Kunstwerke ohne Daten werden direkt, mit Angaben nach einer Frist von sieben Tagen
					gelöscht.
				`,
'se2abc0f78904006c': `Zu viele Einträge`,
'se2c78eaae9032faa': `Anzahl und Maße`,
'se330c71fbddb77c9': `Geburtsort`,
'se629b41ac62684a1': `zusätzliche Urheberrechte`,
'se68398e3c2c760b2': `Token`,
'se6a9371ded4b10d6': `Bestandteile`,
'se6ae9710a2dbbf88': `Verleihungschronik`,
'se7238e44f9dcacee': `Jahr`,
'se77e76829df75be9': `Serie hinzufügen`,
'se80d078fe2e23924': `Kein Array`,
'se82d6481ffb5248f': `Mediendaten gespeichert`,
'se8dca0132c66ae03': `Berechtigungen`,
'se926a1be4644b09d': `Angemeldet bleiben`,
'sea4f08110bb8f15d': `Entfernen`,
'sebec66b8aa58f583': `Keine Zahl`,
'sec72dce4f74b5328': `Erstellungsdatum (Beginn)`,
'sec7b97c2e906c8fc': `Eingeben...`,
'seca29ce17b31f70d': `Veröffentlichung`,
'see1bbbec880f6085': `Nutzungshinweise`,
'see2fcaedf9743ec6': `Ort`,
'sef16aaaba716aa7d': `Anzahl an Objekten`,
'sef49aec68fd1dc66': `Name`,
'sf21fb4f4b9e8911f': `Breite`,
'sf3fe6b5fdb85d50a': `löschen`,
'sf61497d51f2c663f': `Gelöschte Einträge`,
'sf678df8359ba6257': `Fehler beim Hinzufügen des Kunstwerks`,
'sf6947e1f919e6815': `Minimale Länge Unterschritten`,
'sf74160a9dc86ab8f': `Auflösung`,
'sf9aee319a006c9b4': `Hinzufügen`,
'sf9e39190a7ba37b4': `Profil gespeichert`,
'sfa31cf00458e1d13': `Mehr Filter`,
'sfa766d8b35773ac0': `Ort des Versterbens`,
'sfa889eb8ea9148b1': `Datierung`,
'sfbb763a33ac5097b': `Aktueller Zustand`,
'sfcdd8e672c75b713': `Adresse`,
'sfd344c09f3dc2a0a': `Neue Serie`,
'sfde5ee686b1d3d02': `Urheberrechte`,
'sfe4a9344e132c10a': `Verwendete Materialien`,
'sff9d51b6c5a73163': `Alternativer Text`,
'sffdadf0a576fd802': `Höhe`,
's02b2259c7f570ae7': `Tooltip text`,
'seae1c1bb7c02a640': `error/invalidCsrfToken`,
's7a851c97b5bc30b8': `error/notIntBool`,
's11366a6d9faff00f': `error/trueRequired`,
'sa0541ec14dd4b89c': `error/falseRequired`,
'sb8bf01b381b893bb': `error/oneRequired`,
'sb41210de4f64f7e1': `error/zeroRequired`,
'se625ae9f34125ec4': `error/invalidId`,
's072af2f9f637f289': `error/invalidLang`,
's0f15b434f2922b08': `error/notPosInt`,
'sdc8ff1d18f2ecc66': `error/notZeroPosInt`,
's24ed5ee1f343a2b4': `error/uploadPartial`,
's881e3183ff4b701d': `error/uploadMissingTmpDir`,
's860dbc68e0f2b4c8': `error/uploadUnableToWrite`,
'sc21dc776d25eb55a': `error/uploadExtensionFailure`,
'sc34b221a0b8bc676': `error/uploadGenericError`,
'sfb608c3ab924e85c': `error/uploadCategoryNotAllowed`,
's9739d36feb7c7948': `error/uploadTypeNotAllowed`,
's6cbf5b3254dcbbf3': `Request code again`,
'sf0175a45c199e862': str`login code sent to ${0}`,
'scbf29ce484222325': ``,
'se9e3327a8ccafc2e': `Artwork data is completed`,
's90687047080e0924': `Indicates that the data for the artwork is finished and ready for publication`,
's8c4b467b5307d2c1': `When checked, the artwork will be shown on public pages`,
    };
  