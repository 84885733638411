import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/artworkMediaItem.scss';
import textInputStyle from '../../../../scss/textInput.scss';
import multilingualFieldStyle from '../../../../scss/multilingualInput.scss';
import {createMedia, updateMedia, deleteMedia, replaceMedia} from '../../slices/artworkSlice';
import {deepClone} from '../../util/deepClone';
import {selectCurrentProfileDefaultLanguage} from '../../selectors';

@customElement('arc-artwork-media-item')
export class ArtworkMediaItem extends connect(store)(LitElement) {
	static properties = {
		mode: {type: String},
		index: {type: Number},
		value: {type: Object},
		errors: {type: Array},
		artworkId: {type: String},
		edit: {type: Boolean},
		isDropdownOpen: {type: Boolean},
	};

	static styles = [style, textInputStyle, multilingualFieldStyle];

	initialValue = {
		title: [{value: '', lang: 'en'}],
		description: [{value: '', lang: 'en'}],
		url: '',
		type: '',
		alt: [{value: '', lang: 'en'}],
		mediaCopyright: [{value: '', lang: 'en'}],
		artworkCopyright: [{value: '', lang: 'en'}],
		visibility: 'private',
	};

	constructor() {
		super();
		this.value = {...this.initialValue};
		this.index = 0;
		this.artworkId = '';
		this.edit = true;
		this.isDropdownOpen = false;
		this.currentLanguage = 'en';

		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;

		const newData = deepClone(this.value);

		if (section) {
			if (newData[section]?.[field]) {
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}

		this.value = newData;
		this.dispatchValueChange();
	}

	dispatchValueChange() {
		this.dispatchEvent(
			new CustomEvent('value-change', {
				detail: {
					index: this.index,
					value: this.value,
				},
			})
		);
	}

	addFile(file) {
		store.dispatch(createMedia({artworkId: this.artworkId, file: file}));
	}

	removeFile(fileId) {
		store.dispatch(deleteMedia({artworkId: this.artworkId, fileId: fileId}));
	}
	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	handleRemoveFile() {
		this.closeDropdown();
		this.removeFile(this.value.id);
	}
	async handleReplaceFile(e) {
		const file = e.target.files[0];
		if (file) {
			try {
				const response = await store.dispatch(
					replaceMedia({
						artworkId: this.artworkId,
						mediaId: this.value.id,
						file: file,
					})
				);

				if (response && response.payload) {
					// Update the current media item with the new file data
					const updatedValue = {
						...this.value,
						...response.payload,
					};
					this.value = updatedValue;
					this.dispatchValueChange();
				}
			} catch (error) {
				console.error('Failed to replace media:', error);
			}
		}
	}
	render() {
		const mediaItemFields = [
			{
				type: 'multilingualTextInput',
				title: msg('Title'),
				field: 'title',
				section: 'info',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: true,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Description'),
				field: 'description',
				section: 'info',
				placeholder: '',
				multiline: true,
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Alt Text'),
				field: 'alt',
				section: 'info',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Media Copyright'),
				field: 'mediaCopyright',
				section: 'info',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Additional Copyright'),
				field: 'artworkCopyright',
				section: 'info',
				placeholder: '',
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'select',
				title: msg('Visible'),
				field: 'visibility',
				section: 'info',
				// TODO: local i18n for select options
				options: [
					{
						id: 'public',
						name: [
							{lang: 'de', value: 'öffentlich'},
							{lang: 'en', value: 'public'},
						],
					},
					{
						id: 'private',
						name: [
							{lang: 'de', value: 'privat'},
							{lang: 'en', value: 'private'},
						],
					},
				],
				data: this.value,
				errors: this.errors,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
		];

		return html`
			<div class="mediaItemContainer">
				<div class="mediaItem">
					${this.value.thumbs
						? html`
								<div class="imagePreview">
									<p class="mediaIndex">${this.index + 1}.</p>
									<arc-image .file=${this.value} alt="Main Image"></arc-image>
								</div>
						  `
						: ''}
					${this.edit
						? html`
								<arc-image-upload
									label="Media"
									acceptedFormats="image/*"
									.noButtons=${true}
									fileId=${this.value.id}
									.edit=${this.edit}
									@add-file=${(e) => this.addFile(e.detail.file)}
									@remove-file=${(e) => this.removeFile(e.detail.fileId)}
									@replace-file=${(e) => this.handleReplaceFile(e)}
								></arc-image-upload>
						  `
						: ''}
				</div>
				<div class="mediaItemFormContainer">
					<arc-form-grid
						class="mediaItemForm"
						.fields=${mediaItemFields}
						.value=${this.value}
						.errors=${this.errors}
						.edit=${true}
					>
					</arc-form-grid>
				</div>
				<div class="dropdown">
					<button @click=${this.toggleDropdown} aria-label="More Options">
						<svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<circle cx="12" cy="5" r="2" />
							<circle cx="12" cy="12" r="2" />
							<circle cx="12" cy="19" r="2" />
						</svg>
					</button>
					<div class="dropdown-content ${this.isDropdownOpen ? 'show' : ''}">
						<button @click=${this.handleRemoveFile}>${msg('Remove Item')}</button>
						<button>
							<label for="file-upload-${this.index}" style="cursor: pointer;">
								${msg('Replace File')}
							</label>
							<input
								type="file"
								id="file-upload-${this.index}"
								style="display:none"
								@change=${this.handleReplaceFile}
							/>
						</button>
					</div>
				</div>
			</div>
		`;
	}
}
