import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import style from '../../../../../scss/multiselectTags.scss';
import {FormElement} from './formElement';

import down from '../../../icons/down';
import up from '../../../icons/up';
import closeMini from '../../../icons/closeMini';
import check from '../../../icons/check';
import {clearTagSuggestions, searchTags, selectAllTags} from '../../../slices/artworkSlice';
import store from '../../../store';
import {selectTagSuggestions} from '../../../selectors';

@customElement('arc-multiselect-tags')
export class MultiselectTags extends FormElement {
	static properties = {
		...FormElement.properties,
		suggestions: {type: Array},
		value: {type: Array},
		title: {type: String},
		isDropdownOpen: {type: Boolean},
		searchTerm: {type: String},
		multiple: {type: Boolean},
		required: {type: Boolean},
		autosuggestions: {type: Array}
	};

	static styles = [...FormElement.styles, style];

	constructor() {
		super();
		this.suggestions = [];
		this.value = [];
		this.isDropdownOpen = false;
		this.searchTerm = '';
		this.multiple = true;
		this.autosuggestions = [];
	}

	stateChanged(state) {
		super.stateChanged(state);
		this.suggestions = selectTagSuggestions(state);
	}

	connectedCallback() {
		super.connectedCallback();
		document.addEventListener('click', this.handleClickOutside);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		const path = event.composedPath();
		if (this.isDropdownOpen && !path.includes(this)) {
			this.isDropdownOpen = false;
			this.searchTerm = '';
		}
	};

	toggleDropdown = () => {
		this.isDropdownOpen = !this.isDropdownOpen;
		if (this.isDropdownOpen) {
			store.dispatch(clearTagSuggestions());
			this.searchTerm = '';

			this.requestUpdate();
			setTimeout(() => {
				const inputField = this.renderRoot.querySelector('input[type="text"]');
				if (inputField) {
					inputField.focus();
				}
			}, 100);
		} else {
			this.searchTerm = ''; // clear search term when closing dropdown
		}
	};

	handleSearchInput(e) {
		const searchTerm = e.target.value.toLowerCase();
		if (searchTerm.length > 0) {
			store.dispatch(searchTags({searchTerm: searchTerm}));
		} else {
			store.dispatch(clearTagSuggestions());
		}
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			const inputValue = e.target.value.trim().toLowerCase();
			// prevent creating new tags if it exist already, select exsting insetad, only in not-filter mode
			const existingTag = this.suggestions.find(
				(option) => option.name.toLowerCase() === inputValue
			);
			if (existingTag) {
				if (!this.value.includes(existingTag.name)) {
					this.value = [...this.value, existingTag.name];
				}
			} else {
				this.value = [...this.value, inputValue];
			}
			this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.value}}));
			e.target.value = '';
		}
	}

	handleOptionClick = (tag) => {
		const tagName = typeof tag === 'object' ? tag.name : tag;

		if (this.value.includes(tagName)) {
			this.value = this.value.filter((name) => name !== tagName);
		} else {
			this.value = [...this.value, tagName];
		}

		this.dispatchEvent(
			new CustomEvent('value-change', {
				detail: {value: this.value}
			})
		);

		this.requestUpdate();
	};

	getOptionLabel(option) {
		return option && option.name ? option.name : '[Unknown]';
	}

	isSelected(tag) {
		return this.value.includes(tag);
	}

	addTag(tag) {
		if (!this.value.includes(tag)) {
			this.value = [...this.value, tag];
			this.dispatchEvent(new CustomEvent('value-change', {detail: {value: this.value}}));
		}
	}

	removeTag(tag) {
		const tags = this.value.filter((t) => t !== tag);
		this.dispatchValueChange(tags);
	}

	renderOption(option) {
		const optionLabel = typeof option === 'string' ? option : option.tag;
		return html`
			<li
				class="optionItem ${classMap({
					selected: Array.isArray(this.value) && this.value.includes(option.id || option)
				})}"
				@click=${() => this.handleOptionClick(option.id || option)}
			>
				<span class="checkmark">
					${Array.isArray(this.value) && this.isSelected(option.id || option)
						? html`<span>${check}</span>`
						: ''}
				</span>
				${optionLabel}
			</li>
		`;
	}

	render() {
		return html`
			<div class="multiselectInputInfo">
				<div class="title">
					${this.title} ${when(this.required, () => html`<span class="requiredMark">*</span>`)}
				</div>
				${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
			</div>
			<div class="${classMap({'dropdown-container': true, open: this.isDropdownOpen})}">
				<div class="selectHeader" @click=${this.toggleDropdown}>
					${when(
						!this.isDropdownOpen,
						() => html`
							<ul class="selectedTags">
								${this.value.map((selectedId) => {
									const selectedTag = this.suggestions.find((tag) => tag.id === selectedId);
									return html`
										<li class="selectedTag" @click=${() => this.removeTag(selectedId)}>
											${selectedTag ? selectedTag.tag : selectedId}
											<span class="closeIcon">${closeMini}</span>
										</li>
									`;
								})}
							</ul>
							<span class="inputText"
							>${this.value.length ? msg('Start typing...') : msg('Start typing...')}</span
							>
						`,
						() => html`
							<div class="selectedTags">
								${this.value.map((selectedId) => {
									const selectedTag = this.suggestions.find((tag) => tag.id === selectedId);
									return html`
										<span class="selectedTag" @click=${() => this.removeTag(selectedId)}>
											${selectedTag ? selectedTag.tag : selectedId}
											<span class="closeIcon">${closeMini}</span>
										</span>
									`;
								})}
							</div>
							<input
								type="text"
								placeholder="${msg('Start typing...')}"
								.value=${this.searchTerm}
								@input=${this.handleSearchInput}
								@keypress=${this.handleKeyPress}
								@click=${(e) => e.stopPropagation()}
							/>
						`
					)}
					<span class="dropdownArrow">
						${this.isDropdownOpen ? html`<span>${up}</span>` : html`<span>${down}</span>`}
					</span>
				</div>

				${when(
					this.isDropdownOpen,
					() => html`
						<ul class="optionsList">
							${this.suggestions.map((option) => this.renderOption(option))}
						</ul>
					`
				)}
				<arc-errors .errors=${this.errors}></arc-errors>
			</div>
		`;
	}
}
