import {getProfile} from '../slices/profileSlice';

export const profileMiddleware = store => next => async action => {
	const result = next(action);

	//load full profile data after setCurrentProfileId
	if (action.type === 'profile/setCurrentProfileId') {
		store.dispatch(getProfile({id: action.payload}));
	}

	return result;
  };
