export function yearToDate(year) {
	// 2024 => 2024-00-00
	// 20 => 0020-00-00
	// '' => ''

	if (year !== '') {
		// ensure 4 digits for year
		if (year.length > 4) {
			year = year.substr(year.length - 4);
		}
		year = year.padStart(4, '0') + '-00-00';
	}
	return year;
}

export function dateToYear(date) {
	// 2024-00-00 => 2024

	if (date === '') {
		return '';
	} else {
		return date.substr(0, 4);
	}
}
