import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/artworkList.scss';
import {
	fetchMaterials,
	fetchArtworks,
	selectAllArtwork,
	fetchArtworkTypes,
	selectAllMaterials,
	selectAllArtworkTypes,
	selectAllMedia,
	selectSeriesDetail,
	setArtworkQuery,
	fetchSeries,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {
	selectArtworkQuery,
	selectCurrentProfileDefaultLanguage,
	selectLanguage,
} from '../../selectors';
import getRoutes from '../../routes';
import arrowRight from '../../icons/arrowRight';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';
import down from '../../icons/down';
import up from '../../icons/up';

@customElement('arc-artwork-list')
export class ArtworkList extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];

	static properties = {
		materials: {type: Array},
		isMobile: {type: Boolean},
		searchText: {type: String},
		filtersVisible: {type: Boolean},
		noFilters: {type: Boolean},
		seriesId: {type: String},
		contextClass: {type: String},
	};

	constructor() {
		super();
		this.currentLanguage = 'en';
		this.artworks = [];
		this.isMobile = window.innerWidth <= 768;
		this.searchText = '';
		this.filtersVisible = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		let artworks = selectAllArtwork(state);
		const seriesArtworkIds = this.seriesId ? selectSeriesDetail(state)?.artworkIds : [];
		if (seriesArtworkIds.length > 0) {
			artworks = seriesArtworkIds
				.map((id) => artworks.find((artwork) => artwork.id === id))
				.filter((artwork) => artwork);
		}
		this.artworks = artworks;
		this.materials = selectAllMaterials(state);
		this.artworkTypes = selectAllArtworkTypes(state);
		this.mediaCollections = selectAllMedia(state);
		this.artworkQuery = selectArtworkQuery(state);
		this.totalPages = state.artwork.artworkPagination.pagesAmount;
		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);
		this.requestUpdate();
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		this.params = params;
		this.query = query;
		this.data = data;
		this.profileId = params.id;

		if (route === 'artworks') {
			store.dispatch(
				fetchArtworks({
					profileId: this.profileId,
					...this.query,
					...{withCounters: true},
				})
			);
		}
	}

	connectedCallback() {
		super.connectedCallback();
		// listen to event dispatched by series-detail and fetch filtered list
		this._onFetchSeriesArtworks = (event) => {
			const {profileId, seriesId} = event.detail;
			store.dispatch(
				fetchArtworks({
					profileId: profileId,
					seriesId: seriesId,
					...this.query,
					...{withCounters: true},
				})
			);
		};
		window.addEventListener('fetch-series-artworks', this._onFetchSeriesArtworks);
		this.addEventListener('artwork-deleted', this._refreshArtworkList.bind(this));
		window.addEventListener('resize', this._handleResize.bind(this));
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		window.removeEventListener('fetch-series-artworks', this._onFetchSeriesArtworks);
		this.removeEventListener('artwork-deleted', this._refreshArtworkList.bind(this));
		window.removeEventListener('resize', this._handleResize.bind(this));
	}

	_refreshArtworkList() {
		store.dispatch(fetchArtworks({profileId: this.profileId, ...this.query}));
	}

	_handleResize() {
		this.isMobile = window.innerWidth <= 600;
	}

	updateSearch(event) {
		this.searchText = event.target.value.trim().toLowerCase();
	}

	toggleFilters() {
		this.filtersVisible = !this.filtersVisible;
	}
	getOrderQuery(orderBy) {
		return {
			...this.query,
			...{
				orderBy: orderBy,
				orderByDirection: this.artworkQuery.orderByDirection === 'desc' ? 'asc' : 'desc',
			},
		};
	}
	getOrderDirectionIcon(orderBy) {
		if (this.query.orderBy === orderBy) {
			return this.artworkQuery.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	moveArtwork(index, direction) {
		if (direction === 'up' && index > 0) {
			[this.artworks[index - 1], this.artworks[index]] = [
				this.artworks[index],
				this.artworks[index - 1],
			];
		}

		if (direction === 'down' && index < this.artworks.length - 1) {
			[this.artworks[index + 1], this.artworks[index]] = [
				this.artworks[index],
				this.artworks[index + 1],
			];
		}
		this.requestUpdate();

		const updatedArtworkIds = this.artworks.map((artwork) => artwork.id);
		// dispatch changed order to series-detail, so it saves
		this.dispatchEvent(
			new CustomEvent('artwork-order-changed', {
				detail: {artworkIds: updatedArtworkIds},
				bubbles: true,
				composed: true,
			})
		);
	}

	render() {
		return html`
			${!this.noFilters ? html`<arc-artwork-list-filter></arc-artwork-list-filter>` : ''}

			<div class="artworkList">
				${this.artworks.length > 0
					? html`
							${this.isMobile
								? html`
										${repeat(
											this.artworks,
											(artwork) => artwork.id,
											(artwork) => {
												const imageUrl = artwork.firstImage?.url || '';
												const artworkTitle =
													getValueForLanguage(artwork.title, this.currentLanguage, true) ||
													'Untitled';
												return html`
													<div class="artworkListItem">
														<div class="artworkInfo">
															<arc-image
																class="artworkImage"
																.file=${artwork.firstImage ?? null}
																alt="${getValueForLanguage(
																	artwork.firstImage?.info?.alt,
																	this.currentLanguage,
																	true
																)}"
															>
															</arc-image>
															<div>${artworkTitle}</div>
														</div>
														<arc-routerlink
															route="artwork"
															noUnderline
															boldStyle
															.params=${{id: artwork.id, mode: 'edit'}}
														>
															${msg('Edit')} ${arrowRight}
														</arc-routerlink>
													</div>
												`;
											}
										)}
								  `
								: html`
										<div class="artworkListHeader">
											<div></div>
											<div>
												<arc-routerlink
													.route="artworks"
													.query=${this.getOrderQuery('inventoryNumber')}
												>
													${msg('Nr.')} ${this.getOrderDirectionIcon('inventoryNumber')}
												</arc-routerlink>
											</div>
											<div>${msg('Title')}</div>
											<div>${msg('Materials')}</div>
											<div>
												<arc-routerlink
													.route="artworks"
													.query=${this.getOrderQuery('artworkDate')}
												>
													${msg('Year')} ${this.getOrderDirectionIcon('artworkDate')}
												</arc-routerlink>
											</div>
											<div>
												<arc-routerlink .route="artworks" .query=${this.getOrderQuery('status')}>
													${msg('Status')} ${this.getOrderDirectionIcon('status')}
												</arc-routerlink>
											</div>
											<div>
												<arc-routerlink
													.route="artworks"
													.query=${this.getOrderQuery('visibility')}
												>
													${msg('Visibility')} ${this.getOrderDirectionIcon('visibility')}
												</arc-routerlink>
											</div>
										</div>

										${repeat(
											this.artworks,
											(artwork) => artwork.id,
											(artwork, index) => html`
												<div class="artworkListItem">
													${this.seriesId
														? html`
																<div class="arrowControls">
																	<button
																		?disabled=${index === 0}
																		class="${index === 0 ? 'disabledArrow' : ''}"
																		@click=${() => this.moveArtwork(index, 'up')}
																	>
																		${arrowUp}
																	</button>

																	<button
																		?disabled=${index === this.artworks.length - 1}
																		class="${index === this.artworks.length - 1
																			? 'disabledArrow'
																			: ''}"
																		@click=${() => this.moveArtwork(index, 'down')}
																	>
																		${arrowDown}
																	</button>
																</div>
														  `
														: ''}
													<arc-artwork-list-item
														.artwork=${artwork}
														.currentLanguage=${this.currentLanguage}
														.customClass=${this.contextClass}
													></arc-artwork-list-item>
												</div>
											`
										)}
								  `}
							<arc-pagination .totalPages=${this.totalPages}></arc-pagination>
					  `
					: html` <p class="emptyMessage">${msg('No artworks found.')}</p> `}
			</div>
		`;
	}
}
