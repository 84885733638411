import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import style from '../../../../scss/collapsibleSection.scss';

import Down from '../../icons/Down';
import Up from '../../icons/Up';

@customElement('arc-collapsible-section')
export class CollapsibleSection extends LitElement {
	static styles = [style];

	static properties = {
		collapsed: {type: Boolean},
		title: {type: String},
		collapsedSections: {type: Object},
		height: {type: Number}
	};

	constructor() {
		super();
		this.collapsed = true;
		this.title = '';
		this.interval = null;
	}

	connectedCallback() {
		super.connectedCallback();
	}

	firstUpdated() {
		super.firstUpdated();
		this.height = this.getContentHeight();
	}

	toggleCollapse() {
		this.collapsed = !this.collapsed;
		this.height = this.getContentHeight();
		if (!this.collapsed) {
			// update height when content changes
			this.interval = setInterval(() => {
				this.height = this.getContentHeight();
			}, 250);
		} else {
			clearInterval(this.interval);
		}
	}
	slotChange() {
		this.height = this.getContentHeight();
	}
	getContentHeight() {
		const content = this.shadowRoot.querySelector('.content');
		return !content ? '0px' : content.scrollHeight + 'px';
	}

	render() {
		return html`
			<div class="sectionTitleWrapper" @click=${this.toggleCollapse}>
				<h2 class="sectionTitle">${this.title}</h2>
				<span class="chevron">${this.collapsed ? Down : Up}</span>
			</div>
			<div class="content ${this.collapsed ? '' : 'open'}" style="max-height: ${this.collapsed ? '0px' : this.height}">
				<slot @slotchange=${this.slotChange}></slot>
			</div>
		`;
	}
}
