import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {router} from 'lit-element-router';

import store from '../../store';

import style from '../../../../scss/welcome.scss';
import {selectCurrentProfileId} from '../../selectors';
import getRoutes from '../../routes';

@customElement('arc-welcome')
export class Welcome extends connect(store)(router(LitElement)) {
	static styles = [style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.profileId = selectCurrentProfileId(state);
	}

	static get routes() {
		return getRoutes();
	}
	async router(route, params, query, data) {
		this.params = params;
	}
	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	render() {
		const state = store.getState();
		return html`
			<div class="welcomeScreen">
				<div class="welcomeContent">
					<div class="welcomeText">
						<h2>${msg('Welcome to Werkverzeichnisse of Stiftung Kunstfonds')}</h2>
						<slot></slot>
					</div>
					<div class="welcomeButtons">
						<arc-routerlink route="artist" .params=${{id: this.profileId}} noUnderline buttonStyle>
							${msg('Edit Your Profile')}
						</arc-routerlink>

						<arc-routerlink route="account" noUnderline buttonStyle>
							${msg('Account Settings')}
						</arc-routerlink>

						<arc-routerlink route="artworks" .params=${{id: this.profileId}} noUnderline buttonStyle>
							${msg('Add Artwork')}
						</arc-routerlink>
					</div>
				</div>
				<div class="welcomeImage">
					<img src="../assets/img/loginImg.png" />
				</div>
			</div>
    `;
	}
}
