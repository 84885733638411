import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {when} from 'lit/directives/when.js';
import {classMap} from 'lit/directives/class-map.js';
import {navigator, router} from 'lit-element-router';
import {msg} from '@lit/localize';
import {connect} from 'pwa-helpers';
import store from '../../store';
import style from '../../../../scss/header.scss';

import {logout} from '../../slices/accountSlice';
import {switchHeaderDropdownState} from '../../slices/statusSlice';
import {
	selectCurrentProfile,
	selectCurrentProfileId,
	selectLanguage,
	selectProfileDisplayName,
} from '../../selectors';
import down from '../../icons/down';
import {trimObject} from '../../util/trimObject';
import {routeUrl} from '../../util/routeUrl';

@customElement('arc-header')
export class Header extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
		this.isMenuOpen = false;
	}

	stateChanged(state) {
		this.loggedIn = state.account.data.loggedIn;
		this.account = state.account.data.account;
		this.lang = selectLanguage(state);
		this.isHeaderDropdownOpened = state.status.isHeaderDropdownOpened;

		// TODO: get currently selected profileId from status state
		this.profileId = selectCurrentProfileId(state);
	}

	handleDropdown() {
		store.dispatch(switchHeaderDropdownState());
	}

	async onLogout(input) {
		await store.dispatch(logout());
		this.navigate(routeUrl('home', {language: this.lang}));
	}

	toggleMenu() {
		this.isMenuOpen = !this.isMenuOpen;
		this.requestUpdate();
	}

	render() {
		const s = store.getState();

		return html`
			<div class="header">
				<div class="headerLeft">
					<arc-routerlink class="headerLogo" route="home" noUnderline>
						<img src="/assets/img/kfn-logo.svg" alt="Stiftung Kunstfonds" />
					</arc-routerlink>

					<div class="headerTitle">
						<arc-routerlink route="home" noUnderline mobileSmall> Werksverzeichnis </arc-routerlink>
					</div>
				</div>

				<div class="headerRight">
					<div class="headerItem desktop">
						${when(
							this.profileId,
							() => html`
								<arc-routerlink
									route="artworks"
									.params=${{id: this.profileId}}
									.query=${trimObject(this.artworkQuery)}
									noUnderline
								>
									${msg('Artworks')}
								</arc-routerlink>
							`
						)}
					</div>

					${when(
						this.loggedIn,
						() => html` <div
							class="headerItem desktop headerDropdown ${classMap({
								jsActive: this.isHeaderDropdownOpened,
							})}"
							@click="${this.handleDropdown}"
						>
							<div class="headerDropdownTitle">
								<div class="headerDropdownTitleText">${selectProfileDisplayName(s)}</div>
								${down}
							</div>

							<div class="headerDropdownContent">
								<div class="headerDropdownContentLink">
									<arc-routerlink route="artist" .params=${{id: this.profileId}} noUnderline>
										${msg('Edit Profile')}
									</arc-routerlink>
								</div>

								<div class="headerDropdownContentLink">
									<arc-routerlink route="account" .params=${{id: this.account?.id}} noUnderline>
										${msg('Account Settings')}
									</arc-routerlink>
								</div>

								<div class="headerDropdownContentLink">
									<arc-link href="#" @click=${this.onLogout} noUnderline>
										${msg('Logout')}
									</arc-link>
								</div>
							</div>
						</div>`,
						() => html`
						<div class="headerItem desktop">
							<arc-routerlink
								route="requestToken"
								noUnderline
							>
								${msg('Login')}
							</arc-routerlink>
						</div>
						</div>
					`
					)}
					<arc-language-switch class="headerItem desktop languageSwitch"></arc-language-switch>

					<div class="headerItem headerMenu mobileOnly ${this.isMenuOpen ? 'toggled' : ''}">
						<div class="headerMenuTitle">${msg('Menu')}</div>
						<div class="headerMenuIcon" @click="${this.toggleMenu}">
							<div class="line ${classMap({lineOpen: this.isMenuOpen})}"></div>
							<div class="line ${classMap({lineOpen: this.isMenuOpen})}"></div>
							<div class="line ${classMap({lineOpen: this.isMenuOpen})}"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="mobileOverlay">
				<arc-menu-overlay
					.isOpen="${this.isMenuOpen}"
					.loggedIn="${this.loggedIn}"
					.profileDisplayName="${selectProfileDisplayName(s)}"
					@menu-closed="${() => (this.isMenuOpen = false)}"
				></arc-menu-overlay>
			</div>

			<arc-toast-banner></arc-toast-banner>
		`;
	}
}
