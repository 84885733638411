// side effect: store configuration, when decisions are made
import {storeAccount} from '../slices/accountSlice';
import {getProfile, setCurrentProfileId} from '../slices/profileSlice';

export const accountMiddleware = store => next => async action => {
	const result = next(action);

	// store
	if (action.type === 'account/setEmail') {
		store.dispatch(storeAccount());
	}

	// load full main profile data after login/init
	if (action.type === 'account/initSession/fulfilled' || action.type === 'account/authenticateWithToken/fulfilled') {
		const state = store.getState();
		if (state.account.data?.account?.mainProfile) {
			store.dispatch(setCurrentProfileId(state.account.data?.account?.mainProfile.id));
		}
	}
	return result;
};
