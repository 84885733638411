import getRoutes from '../routes';
import store from '../store';

// build route with specific params
export function routeUrl(route, params = {}, query = {}) {
	const s = store.getState();
	const currentRoute = s.status.route;

	// current uri
	const uri = decodeURI(window.location.pathname);

	// current route
	const routes = getRoutes();
	const activeRoute = routes.find(r => r.name === route);

	// build new url
	let newUrl = uri;
	if (activeRoute) {
		//in url pattern, replace :key with the respective properties from params object
		newUrl = activeRoute.pattern;
		const keys = newUrl.match(/:[^\s/]+/g);
		if (keys) {
			keys.forEach(key => {
				const prop = key.replace(':', '');
				if (params.hasOwnProperty(prop)) {
					newUrl = newUrl.replace(key, params[prop]);
				}
			});
		}
	}

	// append url query string from query object
	const queryKeys = Object.keys(query);
	if (queryKeys.length) {
		newUrl += '?';
		queryKeys.forEach((key, index) => {
			newUrl += `${key}=${query[key]}`;
			if (index < queryKeys.length - 1) {
				newUrl += '&';
			}
		});
	}

	return newUrl;
}
function testRoute(uri, pattern) {
	return patternToRegExp(pattern).test(uri);
}
function patternToRegExp(pattern) {
	if (pattern) {
		return new RegExp('^(|/)' + pattern.replace(/:[^\s/]+/g, '([\\w\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff-]+)') + '(|/)$');
	} else {
		return new RegExp('(^$|^/$)');
	}
}
