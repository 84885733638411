import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import style from '../../../../../scss/textInput.scss';
import {FormElement} from './formElement';

@customElement('arc-number')
export class Number extends FormElement {

	static properties = {
		...FormElement.properties,
		multiline: {type: Boolean},
	};

	static styles = [...FormElement.styles, style];

	initialValue = 0;

	handleValueChange(e) {
		const numberValue = parseFloat(e.target.value);
		this.dispatchValueChange(numberValue);
	}
	render() {
		if (!this.edit) {
			return html`
				<div>
					<div class="textInputInfo">
						<div class="title">${this.title}</div>
					</div>
					<p class="value">${this.value ?? ''}</p>
				</div>
			`;
		}
		return html`
			<div class="${classMap({errors: this.hasErrors(), changed: this.inputHasChanged()})}">
				<div class="textInputInfo">
					<div class="title">
						${this.title}
						${when(this.required, () => html`<span class="requiredMark">*</span>`)}
					</div>
					${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
				</div>
				<input
					type="number"
					.required="${this.required}"
					placeholder="${this.placeholder}"
					.value=${this.value ?? ''}
					@input=${this.handleValueChange}
				/>
				<arc-errors .errors=${this.errors}></arc-errors>
			</div>
    `;
	}
}
