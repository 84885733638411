import {msg} from '@lit/localize';
import {showMessage} from '../slices/statusSlice';

export const messageMiddleware = (store) => (next) => async (action) => {
	const result = next(action);
	// store
	if (action.type === 'artwork/create/fulfilled') {
		store.dispatch(
			showMessage({
				message: msg('Artwork added successfully.'),
				messageType: 'success',
			})
		);
	}
	if (action.type === 'artwork/create/rejected') {
		store.dispatch(
			showMessage({
				message: msg('Failed to add artwork. Please try again.'),
				messageType: 'error',
			})
		);
	}
	if (action.type === 'profile/update/fulfilled') {
		store.dispatch(
			showMessage({
				message: msg('Profile saved successfully.'),
				messageType: 'success',
			})
		);
	}
	if (action.type === 'profile/update/rejected') {
		store.dispatch(
			showMessage({
				message: msg('The profile cannot be saved. Please correct the errors.'),
				messageType: 'error',
			})
		);
	}

	if (action.type === 'profile/updateCv/fulfilled') {
		store.dispatch(
			showMessage({
				message: msg('Curriculum vitae saved successfully.'),
				messageType: 'success',
			})
		);
	}
	if (action.type === 'profile/updateCv/rejected') {
		store.dispatch(
			showMessage({
				message: msg('The curriculum vitae cannot be saved. Please correct the errors.'),
				messageType: 'error',
			})
		);
	}

	if (action.type === 'artwork/updateOne/fulfilled') {
		store.dispatch(
			showMessage({
				message: msg('Artwork saved successfully.'),
				messageType: 'success',
			})
		);
	}
	if (action.type === 'artwork/updateOne/rejected') {
		store.dispatch(
			showMessage({
				message: msg('The form cannot be saved. Please correct the errors.'),
				messageType: 'error',
			})
		);
	}

	if (action.type === 'artwork/massUpdateMedia/fulfilled') {
		store.dispatch(
			showMessage({
				message: msg('Media data saved successfully'),
				messageType: 'success',
			})
		);
	}

	if (action.type === 'artwork/massUpdateMedia/rejected') {
		store.dispatch(
			showMessage({
				message: msg('The media date cannot be saved. Please correct the errors.'),
				messageType: 'error',
			})
		);
	}

	if (action.type === 'artwork/delete/rejected') {
		store.dispatch(
			showMessage({
				message: msg('The artwork contains child artworks, please delete them first.'),
				messageType: 'error',
			})
		);
	}

	if (action.type === 'artwork/delete/fulfilled') {
		const {permanentlyRemoved} = action.payload;

		if (permanentlyRemoved) {
			store.dispatch(
				showMessage({
					message: msg('Artwork did not contain any data - it was permanently deleted.'),
					messageType: 'success',
				})
			);
		} else {
			store.dispatch(
				showMessage({
					message: msg('Artwork was moved to the Removed Artworks. It will be deleted in 7 days.'),
					messageType: 'success',
				})
			);
		}
	}
	return result;
};
