import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import style from '../../../../scss/dropdownMenu.scss';
import dots from '../../icons/dots';


@customElement('arc-dropdown-menu')
export class DropdownMenu extends LitElement {
	static styles = [style];

	static properties = {
		open: {type: Boolean}
	};

	constructor() {
		super();
		this.open = false;
	}

	outsideClickHandler(event) {
		// Close dropdown if click is outside of the dropdown
		// use composedPath to check if the event path includes the dropdown
		if (this.open && !event.composedPath().includes(this)) {
			this.toggleDropdown();
		}
	}
	toggleDropdown() {
		this.open = !this.open;
		if (this.open) {
			document.body.addEventListener('click', (event) => this.outsideClickHandler(event));
		} else {
			document.body.removeEventListener('click', (event) => this.outsideClickHandler(event));
		}
	}

	render() {
		return html`
			<div class="dropdown">
				<button @click=${this.toggleDropdown} aria-label="More Options">
					${dots}
				</button>
				<div class="dropdown-content ${classMap({show: this.open})}">
					<slot></slot>
				</div>
			</div>
		`;
	}
}
