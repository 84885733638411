import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {navigator, router} from 'lit-element-router';
import deepEqual from 'deep-equal';
import style from '../../../../scss/seriesDetail.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import store from '../../store';
import getRoutes from '../../routes';

import {
	fetchArtwork,
	fetchSeriesDetail,
	updateSeriesDetail,
	selectSeriesDetail,
	selectArtworkById,
} from '../../slices/artworkSlice';
import {deepClone} from '../../util/deepClone';
import {selectCurrentProfileDefaultLanguage} from '../../selectors';

@customElement('arc-series-detail')
export class SeriesDetail extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, loadingSpinnerStyle, style];
	static properties = {
		seriesId: {type: String},
		seriesDetail: {type: Object},
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		currentLanguage: {type: String},
		edit: {type: Boolean},
		isLoading: {type: Boolean},
	};

	constructor() {
		super();
		this.seriesId = '';
		this.seriesDetail = null;
		this.localFormData = null;
		this.currentLanguage = 'en';
		this.edit = true;
		this.isLoading = false;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.seriesDetail = selectSeriesDetail(state);
		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);
		this.isLoading = state.artwork.loading === 'loading';
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		if (route === 'series-detail' && String(this.seriesId) !== params.seriesId) {
			this.seriesId = params.seriesId;

			await store.dispatch(fetchSeriesDetail({seriesId: this.seriesId}));

			this.localFormData = deepClone(selectSeriesDetail(this.state));
			this.profileId = this.localFormData.profileId;

			// dispatch fetching artworks, but filtered with slight delay
			setTimeout(() => {
				this.dispatchEvent(
					new CustomEvent('fetch-series-artworks', {
						detail: {
							profileId: this.profileId,
							seriesId: this.seriesId,
						},
						bubbles: true,
						composed: true,
					})
				);
			}, 10);
		}

		this.requestUpdate();
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;
		const newData = deepClone(this.localFormData);

		if (section) {
			if (newData[section]?.[field]) {
				newData[section] = newData[section] || {};
				newData[section][field] = value;
			} else {
				newData[section] = {...newData[section], [field]: value};
			}
		} else {
			newData[field] = value;
		}

		this.localFormData = newData;
	}

	connectedCallback() {
		super.connectedCallback();
		this.addEventListener('artwork-order-changed', this._onArtworkOrderChanged.bind(this));
	}

	_onArtworkOrderChanged(event) {
		const {artworkIds} = event.detail;
		this.localFormData.artworkIds = artworkIds;
		this.requestUpdate();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this.removeEventListener('artwork-order-changed', this._onArtworkOrderChanged);
	}

	formHasChanged() {
		return !deepEqual(this.localFormData, selectSeriesDetail(this.state));
	}

	async saveSeries() {
		const updateData = {
			id: this.seriesId,
			...this.localFormData,
			artworkIds: this.localFormData.artworkIds,
		};
		//  save the series including reordered artworkIds
		store.dispatch(updateSeriesDetail(updateData));
	}

	render() {
		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}

		const title = this.localFormData.title?.[0]?.value || 'Untitled';
		const artworkIds = this.localFormData.artworkIds || [];

		const basicInputFields = [
			{
				type: 'multilingualTextInput',
				title: 'Title',
				field: 'title',
				section: null,
				placeholder: '',
				data: this.localFormData,
				handler: this.handleValueChange.bind(this),
				required: true,
			},
			{
				type: 'multilingualTextInput',
				title: 'Subtitle',
				field: 'subtitle',
				section: null,
				placeholder: '',
				data: this.localFormData,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: 'Description',
				field: 'description',
				section: null,
				placeholder: '',
				multiline: true,
				data: this.localFormData,
				handler: this.handleValueChange.bind(this),
			},
		];

		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="series-list" }> Series </arc-routerlink>
							<span>${title}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-button
							title="Save Series"
							type="primary"
							?disabled="${!this.formHasChanged()}"
							@click=${this.saveSeries}
						></arc-button>
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${title}</h2>
				</header>
				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">Series Details</h2>
						<arc-form-grid
							class="formGrid basicAttributes"
							.fields=${basicInputFields}
							.localFormData=${this.localFormData}
							edit=${this.edit}
						></arc-form-grid>
					</div>
				</section>
				<section class="listOfArtworksSection">
					<h2 class="sectionTitle">Artworks in this Series</h2>
					<arc-artwork-list
						contextClass="seriesItem"
						.seriesId="${this.seriesId}"
						.profileId="${this.seriesDetail?.profileId}"
						noFilters="true"
					></arc-artwork-list>
				</section>
			</div>
		`;
	}
}
