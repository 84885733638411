import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit';


// slices
import accountReducer from './slices/accountSlice';
import profileReducer from './slices/profileSlice';
import statusReducer from './slices/statusSlice';
import artworkReducer from './slices/artworkSlice';
import fileReducer from './slices/fileSlice';

// Middlewares
import {accountMiddleware} from './middleware/accountMiddleware';
import {profileMiddleware} from './middleware/profileMiddleware';
import {fileMiddleware} from './middleware/fileMiddleware';
import {messageMiddleware} from './middleware/messageMiddleware';
import {artworkMiddleware} from './middleware/artworkMiddleware';


const store = configureStore({
	reducer: combineReducers(
		{
			account: accountReducer,
			profile: profileReducer,
			status: statusReducer,
			artwork: artworkReducer,
			file: fileReducer
		}
	),
	devTools: process.env.NODE_ENV !== 'production',

	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: {
			// Ignore these action types
			ignoredActions: [
				'account/authenticateWithToken/fulfilled',
				'profile/addProfile/fulfilled',
				'profile/uploadImage/pending',
				'profile/uploadImage/fulfilled',
				'profile/uploadImage/rejected',
				'profile/removeImage/fulfilled',
				'artwork/deleteMedia/fulfilled',
			],
			ignoredActionPaths: ['payload.response.body.errors', 'payload.response.body.input'],
			ignoredPaths: ['profile.image'],
		}
	}).concat(
		accountMiddleware,
		profileMiddleware,
		fileMiddleware,
		messageMiddleware,
		artworkMiddleware
	)

});


export default store;
