import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import store from '../../store';

import style from '../../../../scss/profile/profileList.scss';
import tableStyle from '../../../../scss/table.scss';

import {
	addProfile,
	fetchProfiles,
	selectAllProfiles,
	selectTotalProfiles
} from '../../slices/profileSlice';
import {profileDisplayName} from '../../util/profileDisplayName';
import {createArtwork} from '../../slices/artworkSlice';
import plus from '../../icons/plus';
import {selectLanguage} from '../../selectors';

@customElement('arc-profile-list')
export class ProfileList extends connect(store)(LitElement) {

	static styles = [tableStyle, style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.profiles = selectAllProfiles(state);
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(fetchProfiles());
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	async handleAddProfile() {
		await store.dispatch(addProfile({name: 'New Profile'}));
	}


	render() {
		const state = store.getState();
		return html`

		<div class="profiles">
			<h1 class="profiles-title">
				${msg('Select Profile to edit')}
			</h1>

			<table class="profiles-table">
				<tbody>
				<tr>
					<th>${msg('Profile Name')}</th>
					<th>${msg('Last Modified on')}</th>
					<th>${msg('Permissions')}</th>
					<th></th>
				</tr>

				${repeat(
					this.profiles,
					(profile) => profile.id,
					(profile) => html`
						<td>
							${profileDisplayName(profile)}
						</td>
						<td>
							${profile.modified}
						</td>
						<td>
							[Permissions]
						</td>
						<td>
							<arc-routerlink
								class=""
								route="artist"
								.params=${{id: profile.id, mode: 'edit'}}
								noUnderline
							>
								${msg('Switch to Profile')}
							</arc-routerlink>
						</td>
					`)}

				</tbody>
			</table>
			<arc-button
				title="${msg('Add Profile')}"
				type="primary"
				.additionalInfo="${false}"
				class="addProfileButton"
				showIcon="true"
				@click=${this.handleAddProfile}
			>
				${plus}
			</arc-button>
		</div>
		`;
	}
}
