import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import deepEqual from 'deep-equal';
import {msg} from '@lit/localize';
import style from '../../../../../scss/space.scss';
import {FormElement} from '../form/formElement';

@customElement('arc-diameter')
export class Diameter extends FormElement {

	static properties = {
		value: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		errors: {type: Array, hasChanged: (n, o) => !deepEqual(n, o)},

		index: {type: Number},
		type: {type: String},
	};

	static styles = [...FormElement.styles, style];

	units = [
		{id: 'mm', name: 'mm'},
		{id: 'cm', name: 'cm'},
		{id: 'm', name: 'm'},
		{id: 'in', name: 'in'},
		{id: 'ft', name: 'ft'}
	];

	initialValue = {
		type: 'diameter',
		title: [
			{
				lang: 'en',
				value: 'Diameter'
			},
			{
				lang: 'de',
				value: 'Durchmesser'
			}

		],
		value: {
			unit: 'cm',
			value: ''
		},
	};

	titleChange(value) {
		this.inputValue.title = value;
		this.dispatchValueChange(this.inputValue);
	}
	inputValueValue(value) {
		this.inputValue.value.value = value;
		this.dispatchValueChange(this.inputValue);
	}
	selectValueUnit(value) {
		this.inputValue.value.unit = value;
		this.dispatchValueChange(this.inputValue);
	}

	render() {
		return html`
			<div class="space">

				<arc-multilingual-input
					class="title"
					.title=${msg('Label')}
					.value=${this.inputValue.title}
					.errors=${this.errors?.params?.title}
					.edit=${true}
					.required=${true}
					.multiline=${false}
					@value-change=${(e) => this.titleChange(e.detail.value)}>
				</arc-multilingual-input>

				<div class="dimensions">
					<div class="dimension">
						<div class="formContainer">
							<arc-text-input
								class="value"
								id="value"
								class="formInput width"
								.title="${msg('Diameter')}"
								.edit=${true}
								.multiline=${false}
								.value=${this.inputValue.value.value}
								.errors=${this.errors?.params?.value?.params?.value}
								.required=${true}
								@value-change=${(e) => this.inputValueValue(e.detail.value)}
							></arc-text-input>
							<arc-select
								class="unit"
								.title=""
								.edit=${true}
								.value=${this.inputValue.value.unit}
								.errors=${this.errors?.params?.value.params.unit}
								.options=${this.units}
								.required=${true}
								@value-change=${(e) => this.selectValueUnit(e.detail.value)}>
							</arc-select>
						</div>
					</div>
				</div>
			</div>
    `;
	}
}
