import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {map} from 'lit/directives/map';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';

import {connect} from 'pwa-helpers';

import store from '../../../store';
import style from '../../../../../scss/checkbox.scss';
import {FormElement} from './formElement';

@customElement('arc-checkbox')
export class CheckBox extends FormElement {

	static properties = {
		...FormElement.properties,
		wrapperTitle: {type: String}, // the additional title displayed on top of the wrapper
	};

	static styles = [...FormElement.styles, style];

	initialValue = 0;

	handleValueChange(e) {
		const checked = e.target.checked? 1 : 0;
		this.inputValue = checked;
		this.dispatchValueChange(checked);
	}

	render() {
		const s = store.getState();

		return html`
			<div class="${classMap({errors: this.hasErrors(), changed: this.inputHasChanged()})}">
				<span class="wrapperTitle">${this.wrapperTitle ?? html`&nbsp;`}</span>
				<div class="checkboxWrapper">
					<input type="checkbox" id="arcCheckbox" .checked=${this.value === 1} @change=${this.handleValueChange}>
					<label for="arcCheckbox">
						<div class="title">${this.title}</div>
						${when(this.tooltip, () => html`
							<div class="tooltipWrapper">
								<span class="infoIcon">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
										<circle cx="8.00008" cy="7.99999" r="6.83333" stroke="#707070" />
										<line x1="8" y1="7" x2="8" y2="12.5" stroke="#707070" />
										<circle cx="8" cy="4.75" r="0.75" fill="#707070" />
									</svg>
								</span>
								<span class="tooltipText">${this.tooltip}</span>
							</div>
						`)}
					</label>
					<arc-errors .errors=${this.errors}></arc-errors>
				</div>
			</div>
		`;
	}
}
