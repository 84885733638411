import {createSlice} from '@reduxjs/toolkit';
import {setLocale} from '../components/frontend';

const statusInitialState = {
	status: 'idle',

	// ui state
	isHeaderDropdownOpened: false,

	// base urls
	host: null,
	baseUrl: null,
	apiUrl: null,

	// route
	route: {
		route: null,
		params: null,
		query: null,
	},

	// ui language
	lang: '',

	// content language
	preferredContentLanguage: 'en',
	availableContentLanguages: ['en', 'de'],

	error: {},
	message: null,
	messageType: null,
	showMessage: false,
};
export const statusSlice = createSlice({
	name: 'profile',
	initialState: statusInitialState,
	reducers: {
		setStatus: (state, action) => {
			state = Object.assign(state, action.payload);
		},
		setLang: (state, action) => {
			state.lang = action.payload.lang;
			if (state.lang) {
				setLocale(state.lang);
			}
		},
		switchHeaderDropdownState: (state) => {
			state.isHeaderDropdownOpened = !state.isHeaderDropdownOpened;
		},
		showMessage: (state, action) => {
			state.message = action.payload.message;
			state.messageType = action.payload.messageType;
			state.showMessage = true;
		},
		hideMessage: (state) => {
			state.showMessage = false;
			state.message = null;
			state.messageType = null;
		},
	},
});

export const {setStatus, setLang, switchHeaderDropdownState, showMessage, hideMessage} =
	statusSlice.actions;

export default statusSlice.reducer;
