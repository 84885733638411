import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import getRoutes from '../../routes';
import store from '../../store';
import style from '../../../../scss/artworkMedia.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import {
	fetchMediaCollection,
	selectAllMedia,
	massUpdateMedia,
	selectArtworkById,
	fetchArtwork,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {selectCurrentProfileDefaultLanguage} from '../../selectors';

@customElement('arc-artwork-media')
export class ArtworkMedia extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, loadingSpinnerStyle];

	static properties = {
		mediaCollection: {type: Array},
		artworkId: {type: String},
		isLoading: {type: Boolean},
		edit: {type: Boolean},
		artwork: {type: Object},
		parentArtwork: {type: Object},
	};

	constructor() {
		super();
		this.mediaCollection = null;
		this.artworkId = null;
		this.isLoading = true;
		this.edit = true;
		this.currentLanguage = 'en';
		this.artwork = null;
		this.parentArtwork = null;
	}

	stateChanged(state) {
		this.state = state;
		this.mediaCollection = selectAllMedia(state);
		this.isLoading = state.artwork.loading === 'loading';
		this.artwork = selectArtworkById(this.state, this.artworkId);
		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);

		if (this.artwork?.parentId) {
			this.parentArtwork = selectArtworkById(this.state, this.artwork.parentId);
		}
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params) {
		this.currentLanguage = params.lang;
		this.edit = true; // params.mode === 'edit';
		this.profileId = params.id;
		if (route === 'artwork-media' && String(this.artworkId) !== params.artworkId) {
			this.artworkId = parseInt(params.artworkId, 10);
			store.dispatch(fetchMediaCollection({artworkId: this.artworkId}));

			await store.dispatch(fetchArtwork({id: this.artworkId}));
			if (this.artwork.parentId) {
				store.dispatch(fetchArtwork({id: this.artwork.parentId}));
			}
		}
	}

	addNewMedia() {
		const newMedia = {
			title: [{value: '', lang: 'en'}],
			description: [{value: '', lang: 'en'}],
			url: '',
			type: '',
			thumbs: null,
		};

		this.mediaCollection = [...this.mediaCollection, newMedia];
		this.requestUpdate();
	}

	async saveAllMedia() {
		const mediaItems = this.mediaCollection.map((item, index) => ({
			id: item.id,
			visibility: item.info.visibility || 'private',
			title: item.info.title,
			description: item.info.description,
			alt: item.info.alt,
			artworkCopyright: item.info.artworkCopyright,
			mediaCopyright: item.info.mediaCopyright,
		}));

		store.dispatch(massUpdateMedia({artworkId: this.artworkId, items: mediaItems}));
	}

	handleItemChange(index, value) {
		this.mediaCollection[index] = value;
	}

	render() {
		const title = this.artwork
			? getValueForLanguage(this.artwork.title, this.currentLanguage, true)
			: null;

		return html`
			<div class="artworkMedia">
				<arc-toolbar>
					<div slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
							${when(
								this.parentArtwork,
								() => html` <arc-routerlink
									route="artwork"
									.params=${{id: this.profileId, artworkId: this.parentArtwork.id, mode: 'edit'}}
								>
									${getValueForLanguage(this.parentArtwork.title, this.currentLanguage, true)}
								</arc-routerlink>`
							)}
							<arc-routerlink route="artwork" .params=${{id: this.profileId, artworkId: this.artworkId, mode: 'edit'}}>
								${title}
							</arc-routerlink>
							<span>${msg('Media')}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right">
						<arc-button
							title="${msg('Add Media')}"
							type="secondary"
							.additionalInfo="${false}"
							@click=${this.addNewMedia}
						>
						</arc-button>
						<arc-button
							title="${msg('Save Media')}"
							type="primary"
							@click=${this.saveAllMedia}
						></arc-button>
					</div>
				</arc-toolbar>

				<h2>${msg('Edit Media')}</h2>

				<p>
					Valid formats: png / jpeg / mp4 / tiff / pdf, max: 50mb,<br />Minimum image size 100 x 100
					px
				</p>

				${this.mediaCollection.length > 0
					? html`
							<div class="mediaList">
								${repeat(
									this.mediaCollection,
									(media) => media.id,
									(media, index) => html`
										<arc-artwork-media-item
											.index=${index}
											.value=${media}
											.edit=${this.edit}
											.artworkId=${this.artworkId}
											@value-change=${(e) => this.handleItemChange(e.detail.index, e.detail.value)}
										></arc-artwork-media-item>
									`
								)}
							</div>
					  `
					: html`<p>${msg('No media available for this artwork.')}</p>`}
			</div>
		`;
	}
}
