import {createSlice, createAsyncThunk, createEntityAdapter, current} from '@reduxjs/toolkit';
import agent from '../agent/agent';
import {accountSlice} from './accountSlice';

export const fetchArtworks = createAsyncThunk('artwork/fetchAll', (options, {getState}) => {
	const query = {
		...options,
		profileId: options.profileId,
		isDeleted: options.isDeleted || false,
	};

	return agent
		.get(`${getState().status.apiUrl}/artwork/collection`)
		.query(query)
		.set('Accept', 'application/json')
		.then((response) => response.body)
		.catch((error) => error);
});

export const fetchArtwork = createAsyncThunk('artwork/fetchOne', async ({id}, {getState}) => {
	const {csrf, sid} = getState().account.data.api;
	const apiUrl = getState().status.apiUrl;

	const response = await agent
		.get(`${apiUrl}/artwork/get`)
		.query({id: id})
		.set('Accept', 'application/json')
		.set('Authorization', `Bearer ${sid}`)
		.set('X-CSRF-Token', csrf);

	if (!response.ok) {
		throw new Error(response.statusText || 'Error fetching artwork');
	}

	return response.body.item;
});

export const createArtwork = createAsyncThunk('artwork/create', (options, {getState}) =>
	agent
		.post(`${getState().status.apiUrl}/artwork/create`)
		.send({profileId: options.profileId})
		.set('Accept', 'application/json')
		.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
		.set('X-CSRF-Token', getState().account.data.api.csrf)
		.then((response) => response.body)
		.catch((error) => error)
);
export const createChildArtwork = createAsyncThunk('artwork/createChild', (options, {getState}) =>
	agent
		.post(`${getState().status.apiUrl}/artwork/create`)
		.send({profileId: options.profileId, parentId: options.parentId})
		.set('Accept', 'application/json')
		.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
		.set('X-CSRF-Token', getState().account.data.api.csrf)
		.then((response) => response.body)
		.catch((error) => error)
);

export const updateArtwork = createAsyncThunk(
	'artwork/updateOne',
	async (options, {getState, rejectWithValue}) => {
		try {
			const response = await agent
				.put(`${getState().status.apiUrl}/artwork/update`)
				.send(options)
				.set('Accept', 'application/json');
			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {},
			});
		}
	}
);

export const deleteArtwork = createAsyncThunk(
	'artwork/delete',
	async ({id}, {getState, rejectWithValue}) => {
		const {csrf, sid} = getState().account.data.api;
		const apiUrl = getState().status.apiUrl;

		try {
			const response = await agent
				.delete(`${apiUrl}/artwork/delete`)
				.send({id: id})
				.set('Accept', 'application/json')
				.set('Authorization', `Bearer ${sid}`)
				.set('X-CSRF-Token', csrf);

			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {},
			});
		}
	}
);

export const undeleteArtwork = createAsyncThunk(
	'artwork/undelete',
	async ({id}, {getState, rejectWithValue}) => {
		const {csrf, sid} = getState().account.data.api;
		const apiUrl = getState().status.apiUrl;

		try {
			const response = await agent
				.put(`${apiUrl}/artwork/undelete`)
				.send({id: id})
				.set('Accept', 'application/json')
				.set('Authorization', `Bearer ${sid}`)
				.set('X-CSRF-Token', csrf);

			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {},
			});
		}
	}
);

export const fetchMediaCollection = createAsyncThunk(
	'artwork/fetchMediaCollection',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/artwork/mediaCollection`)
			.query(options)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const createMedia = createAsyncThunk(
	'artwork/createMedia',
	async ({artworkId, file}, {getState}) => {
		const {csrf, sid} = getState().account.data.api;
		const apiUrl = getState().status.apiUrl;
		return await agent
			.post(`${apiUrl}/artwork/createMedia`)
			.field('artworkId', artworkId)
			.attach('file', file)
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${sid}`)
			.set('X-CSRF-Token', csrf);
	}
);

export const updateMedia = createAsyncThunk(
	'artwork/updateMedia',
	async (options, {getState, rejectWithValue}) => {
		try {
			const response = await agent
				.put(`${getState().status.apiUrl}/artwork/updateMedia`)
				.send(options)
				.set('Accept', 'application/json');
			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {},
			});
		}
	}
);

export const replaceMedia = createAsyncThunk(
	'artwork/replaceMedia',
	async ({artworkId, mediaId, file}, {getState, rejectWithValue}) => {
		try {
			const formData = new FormData();
			formData.append('artworkId', artworkId);
			formData.append('id', mediaId); // ID of the media to be replaced
			formData.append('file', file);

			const response = await agent
				.post(`${getState().status.apiUrl}/artwork/replaceMedia`)
				.send(formData)
				.set('Accept', 'application/json');

			return {
				newMedia: response.body, // The new media item returned from the API
				oldMediaId: mediaId, // Pass the old ID so it can be removed
			};
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {},
			});
		}
	}
);

export const massUpdateMedia = createAsyncThunk(
	'artwork/massUpdateMedia',
	async (options, {getState, rejectWithValue}) => {
		console.log('massUpdateMedia', options);
		try {
			const response = await agent
				.put(`${getState().status.apiUrl}/artwork/massUpdateMedia`)
				.send(options)
				.set('Accept', 'application/json');
			return response.body;
		} catch (error) {
			return rejectWithValue({
				message: error.response?.body?.errors[0]?.message || error.message,
				params: error.response?.body?.input?.params || {},
			});
		}
	}
);

export const deleteMedia = createAsyncThunk(
	'artwork/deleteMedia',
	async ({artworkId, fileId}, {getState}) => {
		const {csrf, sid} = getState().account.data.api;
		const apiUrl = getState().status.apiUrl;
		console.log('deleteMedia', artworkId, fileId);
		return await agent
			.delete(`${apiUrl}/artwork/deleteMedia`)
			.send({artworkId: artworkId, id: fileId})
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${sid}`)
			.set('X-CSRF-Token', csrf);
	}
);

export const fetchArtworkTypes = createAsyncThunk('artwork/fetchTypes', async (_, {getState}) => {
	try {
		const response = await agent
			.get(`${getState().status.apiUrl}/artwork/getTypes`)
			.set('Accept', 'application/json');
		return response.body.items;
	} catch (error) {
		throw Error('Failed to fetch artwork types: ' + error.message);
	}
});

export const fetchMaterials = createAsyncThunk('artwork/fetchMaterials', async (_, {getState}) => {
	try {
		const response = await agent
			.get(`${getState().status.apiUrl}/artwork/getMaterials`)
			.set('Accept', 'application/json');
		return response.body.items;
	} catch (error) {
		throw Error('Failed to fetch materials: ' + error.message);
	}
});

export const fetchSeries = createAsyncThunk('artwork/fetchSeries', (options, {getState}) =>
	agent
		.get(`${getState().status.apiUrl}/series/collection`)
		.query(options)
		.set('Accept', 'application/json')
		.then((response) => response.body)
		.catch((error) => error)
);

export const createSeries = createAsyncThunk('series/create', (options, {getState}) =>
	agent
		.post(`${getState().status.apiUrl}/series/create`)
		.send(options)
		.set('Accept', 'application/json')
		.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
		.set('X-CSRF-Token', getState().account.data.api.csrf)
		.then((response) => response.body)
		.catch((error) => error)
);

// Fetch a single series
export const fetchSeriesDetail = createAsyncThunk(
	'artwork/fetchSeriesDetail',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/series/get`)
			.query({id: options.seriesId})
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
			.set('X-CSRF-Token', getState().account.data.api.csrf)
			.then((response) => response.body.item)
			.catch((error) => error)
);

export const updateSeriesDetail = createAsyncThunk(
	'artwork/updateSeriesDetail',
	(options, {getState}) =>
		agent
			.put(`${getState().status.apiUrl}/series/update`)
			.send({
				id: options.id,
				artworkIds: options.artworkIds,
				...options,
			})
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
			.set('X-CSRF-Token', getState().account.data.api.csrf)
			.then((response) => response.body.item)
			.catch((error) => error)
);

export const fetchTags = createAsyncThunk('artwork/fetchTags', async (options, {getState}) => {
	try {
		const response = await agent
			.get(`${getState().status.apiUrl}/artwork/getTags`)
			.query({profileId: options.profileId}) // Include `profileId` from options
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
			.set('X-CSRF-Token', getState().account.data.api.csrf);

		return response.body.items; // Return the list of tags
	} catch (error) {
		throw Error('Failed to fetch tags: ' + error.message);
	}
});
export const searchTags = createAsyncThunk('artwork/searchTags', async (options, {getState}) => {
	try {
		const response = await agent
			.get(`${getState().status.apiUrl}/artwork/searchTags`)
			.query({search: options.searchTerm})
			.set('Accept', 'application/json')
			.set('Authorization', `Bearer ${getState().account.data.api.sid}`)
			.set('X-CSRF-Token', getState().account.data.api.csrf);

		return response.body.items; // Return the list of tags
	} catch (error) {
		throw Error('Failed to fetch tags: ' + error.message);
	}
});

export const artworksAdapter = createEntityAdapter();
export const mediaAdapter = createEntityAdapter();
export const materialsAdapter = createEntityAdapter();
export const artworkTypesAdapter = createEntityAdapter();
export const seriesAdapter = createEntityAdapter();
export const tagsAdapter = createEntityAdapter();
const initialQuery = {
	seriesId: null,
	typeId: null,
	materialId: null,
	search: '',
	status: '', // '', 'draft', 'published'
	visibility: '', // '', 'private', 'public'
	dateRangeBegin: '',
	dateRangeEnd: '',
	orderBy: 'artworkDate', // 'artworkDate', 'inventoryNumber', 'status', 'visibility', 'pos'
	orderByDirection: 'desc', // 'asc', 'desc'
	withCounters: true,
	page: null,
};

export const artworkSlice = createSlice({
	name: 'artwork',
	initialState: {
		artworks: artworksAdapter.getInitialState(),
		mediaCollection: mediaAdapter.getInitialState(),
		artworkTypes: artworkTypesAdapter.getInitialState(),
		materials: materialsAdapter.getInitialState(),
		series: seriesAdapter.getInitialState(),
		tags: tagsAdapter.getInitialState(),
		query: initialQuery,
		counters: null,
		artworkPagination: {
			amount: 0,
			totalAmount: 0,
			pagesAmount: 1,
			page: 1,
		},
		tagSuggestions: [],
		dialog: {
			open: false,
			message: '',
			title: '',
			warningMessage: '',
			artworkTitle: '',
		},
	},
	reducers: {
		setArtworkData: (state, action) => {
			artworksAdapter.upsertOne(state.artworks, action.payload);
		},
		clearMediaCollection: (state) => {
			mediaAdapter.removeAll(state.mediaCollection);
		},
		setArtworkQuery: (state, action) => {
			// merge payload with current query
			state.query = action.payload;
		},
		resetQuery: (state, action) => {
			state.query = initialQuery;
		},
		clearTagSuggestions: (state) => {
			state.tagSuggestions = [];
		},
		openDialog: (state, action) => {
			state.dialog.open = true;
			state.dialog.message = action.payload.message;
			state.dialog.title = action.payload.title || '';
			state.dialog.warningMessage = action.payload.warningMessage || '';
			state.dialog.artworkTitle = action.payload.artworkTitle || '';
		},
		closeDialog: (state) => {
			state.dialog.open = false;
			state.dialog.message = '';
			state.dialog.title = '';
			state.dialog.warningMessage = '';
			state.dialog.artworkTitle = '';
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchArtworks.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchArtworks.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			artworksAdapter.removeAll(state.artworks);
			artworksAdapter.upsertMany(state.artworks, action.payload.collection.items);
			if (action.payload.collection.counters) {
				state.counters = action.payload.collection.counters;
			}
			state.artworkPagination.amount = action.payload.collection.amount;
			state.artworkPagination.totalAmount = action.payload.collection.totalAmount;
			state.artworkPagination.pagesAmount = action.payload.collection.pagesAmount;
			state.artworkPagination.page = action.payload.collection.page;
		});
		builder.addCase(fetchArtworks.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(fetchArtwork.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchArtwork.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			artworksAdapter.upsertOne(state.artworks, action.payload);
		});
		builder.addCase(fetchArtwork.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(createArtwork.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			artworksAdapter.addOne(state.artworks, action.payload.item);
		});
		builder.addCase(createArtwork.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});

		builder.addCase(createChildArtwork.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			artworksAdapter.addOne(state.artworks, action.payload.item);
		});

		builder.addCase(updateArtwork.pending, (state) => {
			state.loading = 'loading';
			//state.error = null;  // Clear error state
		});
		builder.addCase(updateArtwork.fulfilled, (state, action) => {
			state.loading = 'idle';
			artworksAdapter.setOne(state.artworks, action.payload.item);
			state.error = null; // Clear error state
		});
		builder.addCase(updateArtwork.rejected, (state, action) => {
			console.log('updateArtwork.rejected: Setting error state', action.payload);
			state.loading = 'idle';
			state.error = action.payload; // Set error state
		});
		builder.addCase(deleteArtwork.rejected, (state, action) => {
			const {code, message} = action.payload || {};
			if (code === 403) {
				state.blockedMessage = message;
			} else {
				state.error = message;
			}
		});
		builder.addCase(deleteArtwork.fulfilled, (state, action) => {
			const id = action.meta.arg.id;
			const permanentlyRemoved = action.payload.permanentlyRemoved;

			if (permanentlyRemoved) {
				// Remove artwork from the state if it's permanently removed
				artworksAdapter.removeOne(state.artworks, id);
			} else {
				// Mark artwork as deleted if it's only flagged
				const artwork = state.artworks.entities[id];
				if (artwork) {
					artwork.isDeleted = true;
				}
			}
		});
		builder.addCase(undeleteArtwork.fulfilled, (state, action) => {
			const id = action.meta.arg.id;
			const artwork = state.artworks.entities[id];

			if (artwork) {
				// Mark the artwork as no longer deleted
				artwork.isDeleted = false;
			}
		});

		builder.addCase(fetchArtworkTypes.pending, (state, action) => {
			//state.loading = 'idle';
		});
		builder.addCase(fetchArtworkTypes.rejected, (state, action) => {
			//state.loading = 'idle';
			state.error = action.payload;
		});
		builder.addCase(fetchArtworkTypes.fulfilled, (state, action) => {
			artworkTypesAdapter.addMany(state.artworkTypes, action.payload);
		});

		builder.addCase(fetchMaterials.pending, (state, action) => {
			//state.loading = 'idle';
		});
		builder.addCase(fetchMaterials.rejected, (state, action) => {
			//state.loading = 'idle';
			state.error = action.payload;
		});
		builder.addCase(fetchMaterials.fulfilled, (state, action) => {
			materialsAdapter.addMany(state.materials, action.payload);
		});

		//Media
		builder.addCase(fetchMediaCollection.pending, (state, action) => {
			//state.loading = 'idle';
		});
		builder.addCase(fetchMediaCollection.rejected, (state, action) => {
			//state.loading = 'idle';
			state.error = action.payload;
		});
		builder.addCase(fetchMediaCollection.fulfilled, (state, action) => {
			const mediaCollection = action.payload.collection;
			mediaAdapter.upsertMany(state.mediaCollection, mediaCollection.items);
		});

		// Upload media
		builder.addCase(createMedia.fulfilled, (state, action) => {
			state.loading = 'idle';
			const file = action.payload.body.item;
			artworksAdapter.addOne(state.mediaCollection, file);
			state.error = null;
		});
		builder.addCase(createMedia.pending, (state, action) => {
			state.loading = 'loading';
		});
		builder.addCase(createMedia.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});

		// Update media
		builder.addCase(updateMedia.fulfilled, (state, action) => {
			state.loading = 'idle';
			mediaAdapter.updateOne(state.mediaCollection, {
				id: action.payload.id,
				changes: action.payload,
			});
			state.error = null;
		});

		builder.addCase(updateMedia.pending, (state, action) => {
			state.loading = 'loading';
		});

		builder.addCase(updateMedia.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});

		builder.addCase(massUpdateMedia.fulfilled, (state, action) => {
			const updatedMediaItems = action.payload.collection.items;
			console.log('Updated media items from API:', updatedMediaItems);
			mediaAdapter.upsertMany(state.mediaCollection, updatedMediaItems);
			state.error = null;
		});

		//replace image in the media item
		builder.addCase(replaceMedia.fulfilled, (state, action) => {
			const {newMedia, oldMediaId} = action.payload;
			const oldMediaIndex = state.mediaCollection.ids.indexOf(oldMediaId);

			if (oldMediaIndex !== -1) {
				mediaAdapter.removeOne(state.mediaCollection, oldMediaId);
				state.mediaCollection.ids.splice(oldMediaIndex, 0, newMedia.item.id);
				state.mediaCollection.entities[newMedia.item.id] = newMedia.item;
			} else {
				// If old media not found, just add the new media
				mediaAdapter.addOne(state.mediaCollection, newMedia.item);
			}

			state.error = null;
		});

		// Delete media
		builder.addCase(deleteMedia.fulfilled, (state, action) => {
			state.loading = 'idle';
			const fileId = action.meta.arg.fileId;
			artworksAdapter.removeOne(state.mediaCollection, fileId);
			state.error = null;
		});
		builder.addCase(deleteMedia.pending, (state, action) => {
			state.loading = 'loading';
		});
		builder.addCase(deleteMedia.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});

		builder.addCase(fetchSeries.pending, (state, action) => {
			state.loading = 'loading';
			state.error = null;
		});
		builder.addCase(fetchSeries.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			artworksAdapter.upsertMany(state.series, action.payload.collection.items);
		});
		builder.addCase(fetchSeries.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(createSeries.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			artworksAdapter.addOne(state.series, action.payload.item);
		});
		builder.addCase(createSeries.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});
		builder.addCase(fetchSeriesDetail.fulfilled, (state, action) => {
			console.log('Series detail fetched and stored:', action.payload);
			state.seriesDetail = action.payload;
		});
		builder.addCase(fetchSeriesDetail.rejected, (state, action) => {
			console.error('Failed to fetch series detail:', action.error.message);
			state.error = action.error.message;
		});
		builder.addCase(updateSeriesDetail.pending, (state) => {
			state.loading = 'loading';
		});
		builder.addCase(updateSeriesDetail.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.seriesDetail = action.payload;
			seriesAdapter.upsertOne(state.series, action.payload);
			state.error = null;
		});
		builder.addCase(updateSeriesDetail.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.payload;
		});
		builder.addCase(fetchTags.pending, (state) => {
			state.loading = 'idle';
			state.error = null;
		});
		builder.addCase(fetchTags.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			tagsAdapter.setAll(state.tags, action.payload);
		});
		builder.addCase(fetchTags.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
		});

		builder.addCase(searchTags.pending, (state) => {
			state.loading = 'idle';
			state.error = null;
		});
		builder.addCase(searchTags.fulfilled, (state, action) => {
			state.loading = 'idle';
			state.error = null;
			state.tagSuggestions = action.payload;
		});
		builder.addCase(searchTags.rejected, (state, action) => {
			state.loading = 'idle';
			state.error = action.error.message;
			state.tagSuggestions = [];
		});
	},
});

export const {openDialog, closeDialog} = artworkSlice.actions;

export const selectMaterials = (state) => state.artwork.materials;
export const selectMediaByArtworkId = (state, artworkId) =>
	state.artwork.mediaCollection.entities[artworkId]?.media || [];

// Rename the exports for readability in component usage
export const {
	selectById: selectArtworkById,
	selectIds: selectArtworkIds,
	selectEntities: selectArtworkEntities,
	selectAll: selectAllArtwork,
	selectTotal: selectTotalArtwork,
} = artworksAdapter.getSelectors((state) => state.artwork.artworks);

export const {
	selectById: selectMediaById,
	selectIds: selectMediaIds,
	selectEntities: selectMediaEntities,
	selectAll: selectAllMedia,
	selectTotal: selectTotalMedia,
} = mediaAdapter.getSelectors((state) => state.artwork.mediaCollection);

export const {selectById: selectArtworkTypeById, selectAll: selectAllArtworkTypes} =
	artworkTypesAdapter.getSelectors((state) => state.artwork.artworkTypes);

export const {selectById: selectMaterialById, selectAll: selectAllMaterials} =
	materialsAdapter.getSelectors((state) => state.artwork.materials);

export const {
	selectById: selectSerieById,
	selectIds: selectSerieIds,
	selectEntities: selectSerieEntities,
	selectAll: selectAllSerie,
	selectTotal: selectTotalSerie,
} = artworksAdapter.getSelectors((state) => state.artwork.series);

export const selectSeriesDetail = (state) => state.artwork.seriesDetail;

export const {
	selectById: selectTagById,
	selectIds: selectTagIds,
	selectEntities: selectTagEntities,
	selectAll: selectAllTags,
	selectTotal: selectTotalTags,
} = tagsAdapter.getSelectors((state) => state.artwork.tags);

export const {
	setArtworkData,
	clearMediaCollection,
	setArtworkQuery,
	resetQuery,
	clearTagSuggestions,
} = artworkSlice.actions;

export default artworkSlice.reducer;
