import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/artworks.scss';
import {
	createArtwork
} from '../../slices/artworkSlice';
import {selectCurrentProfileDefaultLanguage, selectCurrentProfileId, selectLanguage} from '../../selectors';
import arrowRight from '../../icons/arrowRight';

@customElement('arc-artworks')
export class Artworks extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		materials: {type: Array},
	};

	constructor() {
		super();
		this.currentLanguage = 'en';
		this.artworks = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);

		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);

		// TODO: get currently selected profileId from status state
		this.profileId = selectCurrentProfileId(state);
	}

	connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	handleAddArtwork() {
		store.dispatch(createArtwork({profileId: this.profileId}));
	}

	render() {
		const state = store.getState();

		// console.log('artworksList', this.materials);
		return html`
			<div class="artworks">
				<arc-toolbar>
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right">
						<arc-routerlink route="artworks-bin" .icon=${arrowRight} noUnderline>${msg('Removed artworks')}</arc-routerlink>
						<arc-routerlink route="series-list" .icon=${arrowRight} noUnderline>${msg('Series')}</arc-routerlink>
						<arc-button
							title="${msg('Add Artwork')}"
							type="primary"
							.additionalInfo="${false}"
							class="saveProfileButton"
							@click=${this.handleAddArtwork}
						></arc-button>
					</div>
				</arc-toolbar>
				<h2>${msg('Artworks')}</h2>
				<arc-artwork-list></arc-artwork-list>
			</div>
		`;
	}
}
