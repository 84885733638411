import {LitElement, html, css} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import {navigator, router} from 'lit-element-router';
import {msg, str} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {connect} from 'pwa-helpers';

import style from '../../../../scss/artworkListItem.scss';
import store from '../../store';
import {
	deleteArtwork,
	undeleteArtwork,
	openDialog,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import arrowRight from '../../icons/arrowRight';
import dots from '../../icons/dots';

@customElement('arc-artwork-list-item')
export class ArtworkListItem extends connect(store)(navigator(LitElement)) {
	static styles = [style];

	static properties = {
		artwork: {type: Object},
		currentLanguage: {type: String},
		customClass: {type: String},
		isDropdownOpen: {type: Boolean},
	};

	constructor() {
		super();
		this.isDropdownOpen = false;
		store.subscribe(() => this.requestUpdate());
	}

	connectedCallback() {
		super.connectedCallback();
	}

	toggleDropdown() {
		this.isDropdownOpen = !this.isDropdownOpen;
	}

	closeDropdown() {
		this.isDropdownOpen = false;
	}

	handleDeleteArtwork(artworkId) {
		const title = getValueForLanguage(this.artwork.title, this.currentLanguage, true);
		store.dispatch(
			openDialog({
				title: msg('Remove Artwork'),
				message: msg(
					'Removed Artworks containing data are available in the Removed Artworks for 7 days after removal. Empty artworks are removed immediately.'
				),
				warningMessage: msg(
					str`Removing Artwork "${title}" will immediately remove it from your list of Artworks.`
				),
				buttonText: msg('Remove Artwork'),
				artworkId: artworkId,
			})
		);
		this._handleConfirm = async () => {
			await store.dispatch(deleteArtwork({id: artworkId}));
			this.dispatchEvent(
				new CustomEvent('artwork-deleted', {
					bubbles: true,
					composed: true,
					detail: {artworkId: artworkId},
				})
			);
			window.removeEventListener('confirm', this._handleConfirm);
		};
		window.addEventListener('confirm', this._handleConfirm);
		this.closeDropdown();
	}

	handleRestoreArtwork(artworkId) {
		store.dispatch(undeleteArtwork({id: artworkId}));
	}

	render() {
		const title = getValueForLanguage(this.artwork.title, this.currentLanguage, true);
		const classes = this.customClass ? `artworkItem ${this.customClass}` : 'artworkItem';
		const isBinRoute = window.location.pathname.includes('artworks/bin');
		return html`
			<div class="${classes}">
				<div class="artworkDetail">
					<arc-image
						class="artworkImage"
						.file=${this.artwork.firstImage}
						alt="${getValueForLanguage(
							this.artwork.firstImage?.info?.alt,
							this.currentLanguage,
							true
						)}"
					>
					</arc-image>
				</div>
				<div class="artworkDetail">${this.artwork.inventoryNumber ?? msg('Unknown')}</div>
				<div class="artworkDetail">
					<arc-routerlink
						route="artwork"
						noUnderline
						.params=${{artworkId: this.artwork.id, mode: 'edit'}}
					>
						<h1>
							${when(
								title !== '',
								() => html`${title}`,
								() => html`${msg('Untitled')} (${this.artwork.id})`
							)}
						</h1>
					</arc-routerlink>
				</div>
				<div class="artworkDetail">
					${when(
						this.artwork.materials,
						() => html`
							${this.artwork.materials
								.map((material) => getValueForLanguage(material, this.currentLanguage, true))
								.join(', ')}
						`
					)}
				</div>
				<div class="artworkDetail">
					${this.artwork.artworkDate ? this.artwork.artworkDate.split('-')[0] : msg('Unknown')}
				</div>
				<div class="artworkDetail">${this.artwork.status ?? msg('Unknown')}</div>
				<div class="artworkDetail">${this.artwork.visibility ?? msg('Unknown')}</div>
				<div class="artworkDetail artworkListButtons">
					${isBinRoute
						? html`
							<arc-button
								class="restoreButton"
								title="${msg('Restore')}"
								type="success"
								@click=${() => this.handleRestoreArtwork(this.artwork.id)}
							>
								${msg('Restore')}
							</arc-button>`
						: html`
							<arc-routerlink
								route="artwork"
								noUnderline
								.params=${{artworkId: this.artwork.id, mode: 'edit'}}
							>
								${msg('Edit')} ${arrowRight}
							</arc-routerlink>
							<arc-dropdown-menu>
								<arc-button
									type="deleteButton"
									title="${msg('Remove')}"
									@click=${() => this.handleDeleteArtwork(this.artwork.id)}>
									${msg('Remove')}
								</arc-button>
							</arc-dropdown-menu>`}
				</div>
				<arc-confirmation-dialog></arc-confirmation-dialog>
			</div>
		`;
	}
}
