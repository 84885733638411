function getRoutes() {
	// TODO: set base from environment
	const base = '/';
	return [
		{
			name: 'start',
			pattern: '',
			data: {title: 'Home'},
		},
		{
			name: 'home',
			pattern: base + ':language/',
			data: {title: 'Home'},
		},
		{
			name: 'account',
			pattern: base + ':language/account',
			data: {title: 'Account'},
		},
		{
			name: 'login',
			pattern: base + ':language/login',
			data: {title: 'Login'},
		},
		{
			name: 'requestToken',
			pattern: base + ':language/request',
			data: {title: 'Request Token'},
		},
		{
			name: 'artist',
			pattern: base + ':language/artist/:id',
			data: {title: 'Artist'},
		},
		{
			name: 'artist-cv',
			pattern: base + ':language/artist/:id/cv',
			data: {title: 'CV Artist'},
		},
		{
			name: 'artist-settings',
			pattern: base + ':language/artist/:id/settings',
			data: {title: 'Artist Settings'},
		},
		{
			name: 'artist-profiles',
			pattern: base + ':language/artist/:id/profiles',
			data: {title: 'Profile List'},
		},
		{
			name: 'artworks',
			pattern: base + ':language/artist/:id/artworks',
			data: {title: 'Artworks'},
		},
		{
			name: 'artworks-bin',
			pattern: base + ':language/artist/:id/artworks/bin',
			data: {title: 'Deleted Artworks'},
		},
		{
			name: 'artwork',
			pattern: base + ':language/artist/:id/artwork/:artworkId',
			data: {title: 'Artwork'},
		},
		{
			name: 'artwork-media',
			pattern: base + ':language/artist/:id/artwork/:artworkId/media',
			data: {title: 'Artwork Media'},
		},
		{
			name: 'series-detail',
			pattern: base + ':language/artist/:id/series/:seriesId',
			data: {title: 'Artwork Series'},
		},
		{
			name: 'series-list',
			pattern: base + ':language/artist/:id/series-list',
			data: {title: 'Artwork Series List'},
		},
		{
			name: 'errors',
			pattern: base + ':language/errors',
			data: {title: 'Errors'},
		},
		{
			name: 'not-found',
			pattern: '*',
		},
	];
}

export default getRoutes;
