import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {navigator, router} from 'lit-element-router';
import style from '../../../../scss/seriesList.scss';
import store from '../../store';
import {selectLanguage} from '../../selectors';
import {fetchArtworks, fetchSeries, selectAllSerie} from '../../slices/artworkSlice';
import arrowRight from '../../icons/arrowRight';
import getRoutes from '../../routes';

@customElement('arc-series-list')
export class SeriesList extends connect(store)(router(navigator(LitElement))) {
	static styles = [style];
	static properties = {
		series: {type: Array},
		profileId: {type: String}
	};

	constructor() {
		super();
		this.series = [];
		this.profileId = '';
	}

	static get routes() {
		return getRoutes();
	}
	router(route, params, query, data) {
		this.profileId = params.id;
		if (route === 'series-list') {
			store.dispatch(fetchSeries({profileId: this.profileId}));
		}
	}

	connectedCallback() {
		super.connectedCallback();
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.series = selectAllSerie(state);
	}

	render() {
		return html`
			<div>
				<arc-toolbar>
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
							<span>${msg('Series')}</span>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right"></div>
				</arc-toolbar>
				<h2>Series</h2>
				<div class="seriesListHeader">
					<div>${msg('Title')}</div>
					<div>${msg('Artworks')}</div>
				</div>
				<div class="seriesList">
					<div class="seriesTitles">
						${this.series.length > 0
							? html`
								<ul>
									${this.series.map(
										(serie) => html`
											<li class="seriesItem">
												<arc-routerlink
													noUnderline
													class="seriesDetail"
													route="series-detail"
													.params=${{
														seriesId: serie.id
													}}
												>
													<h2>${serie.title[0]?.value || 'Untitled'}</h2>
												</arc-routerlink>
											</li>
										`
									)}
								</ul>
							`
							: html`<p>No series available</p>`}
					</div>
					<div class="artworksNumbers">
						${this.series.length > 0
							? html`
								<ul>
									${this.series.map(
										(serie) => html`
											<li class="seriesItem">
												<arc-routerlink
													noUnderline
													class="seriesDetail"
													route="series-detail"
													.params=${{
														seriesId: serie.id
													}}
												>
													<div>${serie.artworksAmount}</div>
												</arc-routerlink>
											</li>
										`
									)}
								</ul>
							`
							: html`<p>No series available</p>`}
					</div>
					<div class="editSeries">
						${this.series.length > 0
							? html`
									<ul>
										${this.series.map(
											(serie) => html`
												<li class="seriesItem">
													<arc-routerlink
														noUnderline
														class="seriesDetail"
														route="series-detail"
														.params=${{
															seriesId: serie.id,
														}}
													>
														<h2>${msg('Edit')} ${arrowRight}</h2>
													</arc-routerlink>
												</li>
											`
										)}
									</ul>
							  `
							: html`<p>No series available</p>`}
					</div>
				</div>
			</div>
		`;
	}
}
