import {html, css, LitElement} from 'lit';
import {customElement} from 'lit/decorators.js';
import {classMap} from 'lit/directives/class-map.js';
import {connect} from 'pwa-helpers';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';
import {map} from 'lit/directives/map';

import style from '../../../../../scss/imageUpload.scss';
import store from '../../../store';
import {selectProfileById, removeImage, uploadImage} from '../../../slices/profileSlice';
import {errorMessages} from '../../../util/errorMessages';
import {selectFileById} from '../../../slices/fileSlice';

@customElement('arc-image-upload')
class ImageUpload extends connect(store)(LitElement) {
	static properties = {
		label: {type: String},
		acceptedFormats: {type: String},
		uploadText: {type: String},
		edit: {type: Boolean},
		fileId: {type: Number},
		noButtons: {type: Boolean},
		errors: {type: Array},
		file: {type: Object},
	};

	constructor() {
		super();
		this.label = 'Upload Image';
		this.acceptedFormats = '.jpg, .png, .mov, .mp4';
		this.uploadText = 'Choose a file';
		this.edit = true;
		this.noButtons = false;
		this.type = 'profile';
		this.fileId = null;
		this.errorMessage = '';
	}

	static styles = [style];

	stateChanged(state) {
		if (this.fileId) {
			this.file = selectFileById(state, this.fileId);
			this.imageUrl = this.file?.url;
		}
	}

	connectedCallback() {
		super.connectedCallback();
	}

	addFile(event) {
		const file = event.target.files[0];
		console.log('addFile', file);
		if (file) {
			this.dispatchEvent(new CustomEvent('add-file', {detail: {file: file}}));
		}
	}

	removeFile(event) {
		console.log('removeFile', this.fileId);
		if (this.fileId) {
			this.dispatchEvent(new CustomEvent('remove-file', {detail: {fileId: this.fileId}}));
		}
	}
	triggerFileInput() {
		this.shadowRoot.getElementById('fileInput').click();
	}

	render() {
		const hasFile = !!this.fileId;
		const uploadButtonClasses = {
			uploadButtons: true,
			noButtons: this.noButtons,
		};
		return html`
			<div class="${this.type}">
				<div class="uploadContainer">
					<input
						id="fileInput"
						class="hiddenInput"
						type="file"
						accept="${this.acceptedFormats}"
						@change="${this.addFile}"
					/>

						<div class="uploadButtons">
							${
								hasFile
									? html`
											<arc-button
												title="${msg('Remove')}"
												class="${classMap(uploadButtonClasses)}"
												type="secondary"
												additionalInfo="${true}"
												@click="${this.removeFile}"
											></arc-button>
									  `
									: html`
											<arc-button
												title="${msg('Add')}"
												type="secondary"
												additionalInfo="${true}"
												@click="${this.triggerFileInput}"
											></arc-button>
									  `
							}
						</div>

						<!--
						<div class="dropdown">
							<button class="dropbtn"></button>
							<div class="dropdown-content">
								<a href="#" @click="${this.triggerFileInput}">${msg('Replace')}</a>
								<a href="#" @click="${this.removeFile}">${msg('Remove')}</a>
							</div>
						</div>
						-->

						<p class="uploadInfo ${classMap(uploadButtonClasses)}">png / jpeg / max: 1mb</p>
					</div>
				</div>
				<arc-errors .errors=${this.errors}></arc-errors>
			</div>
		`;
	}
}
