import {html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {classMap} from 'lit/directives/class-map.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import style from '../../../../../scss/multiselectTags.scss';
import {FormElement} from './formElement';

import down from '../../../icons/down';
import up from '../../../icons/up';
import closeMini from '../../../icons/closeMini';
import check from '../../../icons/check';
import {clearTagSuggestions, searchTags, selectAllTags} from '../../../slices/artworkSlice';
import store from '../../../store';
import {selectTagSuggestions} from '../../../selectors';
import {deepClone} from '../../../util/deepClone';

@customElement('arc-multiselect-tags-filter')
export class MultiselectTagsFilter extends FormElement {
	static properties = {
		...FormElement.properties,
		value: {type: String},
		title: {type: String},
		isDropdownOpen: {type: Boolean},
		searchTerm: {type: String},
		multiple: {type: Boolean},
		required: {type: Boolean},
		tags: {type: Array},
		filteredTags: {type: Array},
	};

	static styles = [...FormElement.styles, style];

	constructor() {
		super();
		this.tags = [];
		this.filteredTags = [];
		this.value = '';
		this.valueArray = [];
		this.isDropdownOpen = false;
		this.searchTerm = '';
		this.multiple = true;
	}

	stateChanged(state) {
		super.stateChanged(state);

		// convert string like [id1, id2] to actual array of ids
		this.valueArray = [];
		if (typeof this.value === 'string') {
			this.valueArray = JSON.parse(this.value);
		}
		this.filterOptions();
	}
	filterOptions() {
		if (this.searchTerm.length > 0) {
			// filter tags by search term
			this.filteredTags = this.tags.filter((tag) => tag.tag.toLowerCase().includes(this.searchTerm.toLowerCase()));
		} else {
			this.filteredTags = deepClone(this.tags);
		}
	}

	connectedCallback() {
		super.connectedCallback();
		document.addEventListener('click', this.handleClickOutside);
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		document.removeEventListener('click', this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		const path = event.composedPath();
		if (this.isDropdownOpen && !path.includes(this)) {
			this.isDropdownOpen = false;
			this.searchTerm = '';
			this.filterOptions();
		}
	};

	toggleDropdown = () => {
		this.isDropdownOpen = !this.isDropdownOpen;
		if (this.isDropdownOpen) {
			this.searchTerm = '';

			this.requestUpdate();
			setTimeout(() => {
				const inputField = this.renderRoot.querySelector('input[type="text"]');
				if (inputField) {
					inputField.focus();
				}
			}, 100);
		} else {
			this.searchTerm = ''; // clear search term when closing dropdown
		}
		this.filterOptions();
	};

	handleSearchInput(e) {
		this.searchTerm = e.target.value;
		this.filterOptions();
	}

	handleKeyPress(e) {
		if (e.key === 'Enter') {
			// if only one tag is left select it
			if (this.filteredTags.length === 1) {
				this.valueArray = [...this.valueArray, this.filteredTags[0].id];
				this.dispatchValueChange(JSON.stringify(this.valueArray));
				this.searchTerm = '';
			}
		}
	}

	handleOptionClick = (tag) => {
		if (this.valueArray.includes(tag.id)) {
			this.valueArray = this.valueArray.filter((id) => id !== tag.id);
		} else {
			this.valueArray = [...this.valueArray, tag.id];
		}
		if (this.valueArray.length === 0) {
			this.dispatchValueChange('');
		} else {
			this.dispatchValueChange(JSON.stringify(this.valueArray));
		}
		this.searchTerm = '';
		this.requestUpdate();
	};

	getOptionLabel(option) {
		return option && option.name ? option.name : '[Unknown]';
	}

	isSelected(tag) {
		return this.valueArray.includes(tag);
	}

	removeTag(tagId) {
		this.valueArray = this.valueArray.filter((id) => id !== tagId);
		if (this.valueArray.length === 0) {
			this.dispatchValueChange('');
		} else {
			this.dispatchValueChange(JSON.stringify(this.valueArray));
		}
	}

	renderOption(option) {
		const optionLabel = option.tag;
		const isDisabled = option.counter === 0;
		const count = option.counter !== undefined ? ` (${option.counter})` : '';

		return html`
			<li
				class="optionItem ${classMap({
					selected: Array.isArray(this.valueArray) && this.valueArray.includes(option.id),
					disabled: isDisabled,
				})}"
				@click=${() => this.handleOptionClick(option)}
			>
				<span class="checkmark">
					${Array.isArray(this.valueArray) && this.isSelected(option.id)
						? html`<span>${check}</span>`
						: ''}
				</span>
				${optionLabel}${count}
			</li>
		`;
	}

	render() {
		return html`
			<div class="multiselectInputInfo">
				<div class="title">
					${this.title} ${when(this.required, () => html`<span class="requiredMark">*</span>`)}
				</div>
				${this.icon ? html`<span class="icon"><slot name="icon"></slot></span>` : ''}
			</div>
			<div class="${classMap({'dropdown-container': true, open: this.isDropdownOpen})}">
				<div class="selectHeader" @click=${this.toggleDropdown}>
					${when(
						!this.isDropdownOpen,
						() => html`
							<ul class="selectedTags">
								${this.valueArray.map((selectedId) => {
									const selectedTag = this.tags.find((tag) => tag.id === selectedId);
									return html`
										<li class="selectedTag" @click=${() => this.removeTag(selectedId)}>
											${selectedTag ? selectedTag.tag : selectedId}
											<span class="closeIcon">${closeMini}</span>
										</li>
									`;
								})}
							</ul>
							<span class="inputText"
							>${this.valueArray.length ? msg('Start typing...') : msg('Start typing...')}</span
							>
						`,
						() => html`
							<div class="selectedTags">
								${this.valueArray.map((selectedId) => {
									const selectedTag = this.tags.find((tag) => tag.id === selectedId);
									return html`
										<span class="selectedTag" @click=${() => this.removeTag(selectedId)}>
											${selectedTag ? selectedTag.tag : selectedId}
											<span class="closeIcon">${closeMini}</span>
										</span>
									`;
								})}
							</div>
							<input
								type="text"
								placeholder="${msg('Start typing...')}"
								.value=${this.searchTerm}
								@input=${this.handleSearchInput}
								@keypress=${this.handleKeyPress}
								@click=${(e) => e.stopPropagation()}
							/>
						`
					)}
					<span class="dropdownArrow">
						${this.isDropdownOpen ? html`<span>${up}</span>` : html`<span>${down}</span>`}
					</span>
				</div>

				${when(
					this.isDropdownOpen,
					() => html`
						<ul class="optionsList">
							${this.filteredTags.map((option) => this.renderOption(option))}
						</ul>
					`
				)}
				<arc-errors .errors=${this.errors}></arc-errors>
			</div>
		`;
	}
}
