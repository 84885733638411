import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {navigator, router} from 'lit-element-router';
import {repeat} from 'lit/directives/repeat.js';
import {when} from 'lit/directives/when.js';
import {msg} from '@lit/localize';
import deepEqual from 'deep-equal';
import {trimObject} from '../../util/trimObject';

import {routeUrl} from '../../util/routeUrl';
import store from '../../store';
import style from '../../../../scss/artworkDetail.scss';
import formPageStyle from '../../../../scss/formPage.scss';
import styleMeasurements from '../../../../scss/measurements.scss';
import loadingSpinnerStyle from '../../../../scss/loadingSpinner.scss';
import keyValueListStyle from '../../../../scss/keyValueList.scss';

import getRoutes from '../../routes';
import {
	fetchArtwork,
	fetchArtworkTypes,
	selectArtworkById,
	updateArtwork,
	setArtworkData,
	fetchMaterials,
	fetchMediaCollection,
	createMedia,
	selectAllMedia,
	deleteMedia,
	fetchArtworks,
	createArtwork,
	createChildArtwork,
	selectAllArtwork,
	clearMediaCollection,
	selectAllMaterials,
	selectAllArtworkTypes,
	selectAllSerie,
	fetchSeries,
	fetchTags,
	selectAllTags,
	deleteArtwork,
	openDialog,
} from '../../slices/artworkSlice';
import {getValueForLanguage} from '../../util/getValueForLanguage';
import {deepClone} from '../../util/deepClone';
import {selectProfileById} from '../../slices/profileSlice';
import {selectArtworkQuery, selectCurrentProfileDefaultLanguage} from '../../selectors';
import plus from '../../icons/plus';
import arrowRight from '../../icons/arrowRight';

@customElement('arc-artwork-detail')
export class ArtworkDetail extends connect(store)(router(navigator(LitElement))) {
	static styles = [formPageStyle, styleMeasurements, loadingSpinnerStyle, keyValueListStyle, style];

	static properties = {
		localFormData: {type: Object, hasChanged: (n, o) => !deepEqual(n, o)},
		media: {type: Array},
		artworkTypes: {type: Array},
	};

	constructor() {
		super();
		this.localFormData = null;
		this.artworkTypes = [];
		this.materials = [];
		this.media = [];
		this.currentLanguage = 'en';

		this.edit = true;
		this.isLoading = false;
		this.artworkId = null;
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.errors = state.artwork.error;
		this.isLoading = state.artwork.loading === 'loading';

		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);

		this.artworkTypes = selectAllArtworkTypes(state);
		this.materials = selectAllMaterials(state);
		this.artworkQuery = selectArtworkQuery(state);
		this.mediaCollection = selectAllMedia(state).filter(
			(artwork) => artwork.artworkId === this.artworkId
		);
		this.childArtworks = selectAllArtwork(state).filter(
			(artwork) => artwork.parentId === this.artworkId
		);
		if (this.localFormData?.parentId) {
			this.parentArtwork = selectArtworkById(this.state, this.localFormData.parentId);
		} else {
			this.parentArtwork = null;
		}
	}

	static get routes() {
		return getRoutes();
	}

	async router(route, params, query, data) {
		this.params = params;

		// get parameters from the route: artwork id, edit mode
		this.edit = true; //params.mode === 'edit';
		this.profileId = params.id;
		if (route === 'artwork' && String(this.artworkId) !== params.artworkId) {
			this.artworkId = parseInt(params.artworkId, 10);
			// fetch artwork from backend
			this.localFormData = null;
			await store.dispatch(fetchArtwork({id: this.artworkId}));
			store.dispatch(fetchMediaCollection({artworkId: this.artworkId}));

			// clone data for local editing (component state)
			this.localFormData = deepClone(selectArtworkById(this.state, this.artworkId) ?? null);
			if (this.localFormData && this.localFormData.parentId && this.localFormData.parentId !== 0) {
				await store.dispatch(fetchArtwork({id: this.localFormData.parentId}));
			}
		}
		this.requestUpdate();
	}

	connectedCallback() {
		super.connectedCallback();
		// child artworks
		store.dispatch(fetchArtworks({profileId: this.profileId, parentId: this.artworkId}));

		if (selectAllMaterials(this.state).length === 0) {
			store.dispatch(fetchMaterials());
		}
		if (selectAllArtworkTypes(this.state).length === 0) {
			store.dispatch(fetchArtworkTypes());
		}

		if (selectAllSerie(this.state).length === 0) {
			store.dispatch(fetchSeries({profileId: this.profileId}));
		}

		this.loadMediaCollection();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	async loadMediaCollection() {
		console.log('clearMediaCollection');
		await store.dispatch(clearMediaCollection());
		store.dispatch(fetchMediaCollection({artworkId: this.artworkId}));
	}

	handleValueChange(fieldData, value) {
		const section = fieldData.section;
		const field = fieldData.field;
		// update localFormData with new value in section and field
		const newData = deepClone(this.localFormData); // clone current date

		if (section) {
			if (newData[section]?.[field]) {
				newData[section] = newData[section] || {};
				newData[section][field] = value; // update field
			} else {
				newData[section] = {...newData[section], [field]: value}; // create new field
			}
		} else {
			newData[field] = value; // update field
		}
		this.localFormData = newData; // assign (updates reference, triggers update)
	}

	handleMeasurementChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.measurements = value;
		this.localFormData = newData;
		this.requestUpdate('localFormData');
	}

	handleInventoryNumbersChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.inventoryNumbers = value;
		this.localFormData = newData; // assign (updates reference, triggers update)
		this.requestUpdate('localFormData');
	}

	handleVisibilityChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.visibility = value === 1 ? 'public' : 'private';
		this.localFormData = newData;
		this.requestUpdate('localFormData');
	}
	handleStatusChange(value) {
		const newData = deepClone(this.localFormData); // clone current date
		newData.status = value === 1 ? 'published' : 'draft';
		this.localFormData = newData;
		this.requestUpdate('localFormData');
	}

	async saveArtwork() {
		store.dispatch(updateArtwork(this.localFormData));
	}

	cancelEditing() {
		// cancel editing and go back to preview
		this.navigate(routeUrl('artwork', {...this.params, mode: 'preview'}));
	}

	artworksList() {
		// cancel editing and go back to preview
		this.navigate(routeUrl('artworks', {...this.params}));
	}

	goBackToEditing() {
		// go back to editing
		this.navigate(routeUrl('artwork', {...this.params, mode: 'edit'}));
	}

	addFile(file) {
		store.dispatch(createMedia({artworkId: this.artworkId, file: file}));
	}

	removeFile(fileId) {
		store.dispatch(deleteMedia({artworkId: this.artworkId, fileId: fileId}));
	}

	async handleAddChildArtwork() {
		await store.dispatch(createChildArtwork({profileId: this.profileId, parentId: this.artworkId}));
	}

	editMedia() {
		this.navigate(routeUrl('artwork-media', {...this.params}));
	}

	formHasChanged() {
		// check if local form data is different
		const newData = deepClone(this.localFormData ?? null);
		const oldData = deepClone(selectArtworkById(this.state, this.artworkId) ?? null);
		if (newData && newData.hasOwnProperty('modified')) {
			delete newData.modified;
		}
		if (oldData && oldData.hasOwnProperty('modified')) {
			delete oldData.modified;
		}
		return !deepEqual(newData, oldData);
	}
	handleDeleteArtwork(artworkId) {
		store.dispatch(
			openDialog({
				title: msg('Remove Artwork'),
				message: msg(
					'Removed Artworks containing data are available in the Removed Artworks for 7 days after removal. Empty artworks are removed immediately.'
				),
				warningMessage: msg(
					'Removing this Artwork will immediately remove it from your list of Artworks.'
				),
				artworkId: artworkId,
			})
		);
		this._handleConfirm = async () => {
			const result = await store.dispatch(deleteArtwork({id: artworkId}));
			if (result.meta.requestStatus === 'fulfilled') {
				// Dispatch custom event and redirect after successful deletion
				this.dispatchEvent(
					new CustomEvent('artwork-deleted', {
						bubbles: true,
						composed: true,
						detail: {artworkId: artworkId},
					})
				);

				// Use your router or lit-router navigation method
				this.navigate(routeUrl('artworks', {...this.params}));
			}
			window.removeEventListener('confirm', this._handleConfirm);
		};
		window.addEventListener('confirm', this._handleConfirm);
	}

	render() {
		//loading message before the form loads with data
		if (this.isLoading || !this.localFormData) {
			return html`
				<div class="loading-overlay">
					<div class="spinner"></div>
				</div>
			`;
		}

		const selectedTags = this.localFormData.tags || [];

		const basicInputFields = [
			{
				type: 'select',
				title: msg('Type of Artwork'),
				field: 'typeId',
				options: this.artworkTypes,
				section: null,
				multiple: false,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Title'),
				field: 'title',
				section: null,
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Subtitle'),
				field: 'subtitle',
				section: null,
				placeholder: '',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Description'),
				field: 'description',
				section: null,
				placeholder: '',
				multiline: true,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'date',
				title: msg('Artwork Date'),
				field: 'artworkDate',
				section: null,
				granularity: 'all',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: true,
			},
			{
				type: 'multiselect',
				title: msg('Materials Used'),
				field: 'materialIds',
				options: this.materials,
				multiple: true,
				section: null,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: true,
				showSelectedCount: true,
				showCounters: false,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Detailed Materials Definition'),
				field: 'technique',
				section: null,
				placeholder: '',
				data: this.localFormData,
				multiline: true,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'textInput',
				title: msg('Link'),
				field: 'link',
				section: null,
				placeholder: '',
				multilingual: false,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'series',
				title: msg('Series'),
				field: 'seriesId',
				section: null,
				tooltip: '',
				artworkId: this.artworkId,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'tags',
				title: msg('Tags'),
				field: 'tags',
				section: null,
				multilingual: false,
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				required: false,
			},
		];

		const objectsAmountFields = [
			{
				type: 'number',
				title: msg('Objects Amount'),
				field: 'objectsAmount',
				section: null,
				errors: this.errors,
				data: this.localFormData,
				multilingual: false,
				required: true,
				handler: this.handleValueChange.bind(this),
			},
		];

		const historyStateOptions = [
			{value: '', label: msg('')},
			{value: 'sold', label: msg('Sold')},
			{value: 'loaned', label: msg('Loaned')},
			{value: 'unsellable', label: msg('Unsellable')},
		];
		const historyInputFields = [
			{
				type: 'select',
				title: msg('State'),
				field: 'state',
				section: 'history',
				options: historyStateOptions.map((option) => ({
					id: option.value,
					name: [{lang: 'en', value: option.label}],
				})),
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},

			{
				type: 'textInput',
				title: msg('Location'),
				field: 'location',
				section: 'history',
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Exhibition History'),
				field: 'exhibitionHistory',
				section: 'history',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Lending History'),
				field: 'lendingHistory',
				section: 'history',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Mentions'),
				field: 'mentions',
				section: 'history',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Remarks'),
				field: 'remarks',
				section: 'history',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
		];

		const miscInputFields = [
			{
				type: 'textInput',
				title: msg('Purchasing Price'),
				field: 'purchasingPrice',
				section: 'misc',
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'textInput',
				title: msg('Value Category'),
				field: 'valueCategory',
				section: 'misc',
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'checkbox',
				title: msg('Signed'),
				field: 'signed',
				section: 'misc',
				data: this.localFormData,
				errors: this.errors,
				multilingual: false,
				required: false,
				handler: this.handleValueChange.bind(this),
			},
			{
				type: 'multilingualTextInput',
				title: msg('Current Condition'),
				field: 'currentCondition',
				section: 'misc',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
			{
				type: 'multilingualTextInput',
				title: msg('Usage Notes'),
				field: 'usageNotes',
				section: 'misc',
				data: this.localFormData,
				errors: this.errors,
				handler: this.handleValueChange.bind(this),
				multilingual: true,
				required: false,
				multiline: true,
			},
		];

		const title = this.localFormData
			? getValueForLanguage(this.localFormData.title, this.currentLanguage, true)
			: null;

		// Check if we are inside the parent artwork or child
		const isParentArtwork = !this.localFormData.parentId;

		return html`
			<div class="formPage">
				<arc-toolbar class="toolbar">
					<div slot="left" class="leftSlot">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks" .query=${trimObject(this.artworkQuery)}>${msg(
								'Artworks'
							)}
							</arc-routerlink>
							${when(
								this.parentArtwork,
								() => html`
									<arc-routerlink
										route="artwork"
										.params=${{id: this.profileId, artworkId: this.parentArtwork.id, mode: 'edit'}}
									>
										${getValueForLanguage(this.parentArtwork.title, this.currentLanguage, true)}
									</arc-routerlink>`
							)}
							<span>${title}</span>
						</arc-breadcrumbs>
					</div>
					<div slot="right" class="rightSlot">
						<arc-routerlink
							route="artwork-media"
							.params=${{id: this.profileId, artworkId: this.artworkId}}
							.icon=${arrowRight} nounderline>
							${msg('Edit Media')}
						</arc-routerlink>
						<arc-button
							title="${msg('Remove Artwork')}"
							type="danger"
							class="secondary"
							@click=${() => this.handleDeleteArtwork(this.artworkId)}>
							${msg('Delete')}
						</arc-button>
						<arc-button
							title="${msg('Save Artwork')}"
							type="primary"
							.additionalInfo="${false}"
							?disabled="${!this.formHasChanged()}"
							class="saveArtworkButton"
							@click=${this.saveArtwork}>
						</arc-button>
					</div>
				</arc-toolbar>
				<header>
					<h2 class="pageTitle">${title}</h2>
				</header>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Basic attributes')}</h2>
						<arc-form-grid
							class="formGrid basicAttributes"
							.fields=${basicInputFields}
							.localFormData=${this.localFormData}
							.errors=${this.errors}
							.edit=${this.edit}>
						</arc-form-grid>
					</div>
					<div class="rightColumn">
						<div class="visibilityInfo">
							<h2 class="sectionTitle">${msg('Publication')}</h2>
							<arc-checkbox
								wrapperTitle="Draft"
								title="${msg('Artwork data is completed')}"
								class="formInput checkbox"
								.value=${this.localFormData.status === 'published' ? 1 : 0}
								.showIcon=${true}
								.tooltip="${msg('Indicates that the data for the artwork is finished and ready for publication')}"
								@value-change=${(e) => this.handleStatusChange(e.detail.value)}
							>
							</arc-checkbox>
							<arc-checkbox
								wrapperTitle="Visibility"
								title="${msg('Artwork is visible to public')}"
								class="formInput checkbox"
								.value=${this.localFormData.visibility === 'public' ? 1 : 0}
								.showIcon=${true}
								.tooltip="${msg('When checked, the artwork will be shown on public pages')}"
								@value-change=${(e) => this.handleVisibilityChange(e.detail.value)}
							>
							</arc-checkbox>
						</div>
						<div class="mediaSection">
							<h2 class="sectionTitle">${msg('Media')}</h2>
							<div class="artworkMedia">
								${repeat(
									this.mediaCollection,
									(media) => media.id,
									(media) => html`
										<div class="imagePreview">
											<arc-image .file=${media} alt="Main Image"></arc-image>
										</div>
									`
								)}
							</div>
							<div class="editMediaButtonContainer">
								<arc-routerlink
									route="artwork-media"
									buttonStyle
									.params=${{language: this.status.lang, id: this.profileId, artworkId: this.artworkId, mode: 'edit'}}
									noUnderline
									buttonStyle
								>
									${msg('Edit Media')}
								</arc-routerlink>
							</div>
						</div>
					</div>

				</section>

				<section>
					<div class="leftColumn">
						<arc-collapsible-section title="${msg('Count and Measurements')}">
							<arc-form-grid
								class="formGrid objectsAmount"
								.fields=${objectsAmountFields}
								.localFormData=${this.localFormData}
								.errors=${this.errors}
								.edit=${this.edit}
							>
							</arc-form-grid>
							<arc-form-repeat
								.components=${[
									{component: 'arc-space', type: 'space', label: msg('Space')},
									{component: 'arc-resolution', type: 'resolution', label: msg('Resolution')},
									{component: 'arc-diagonal', type: 'diagonal', label: msg('Diagonal')},
									{component: 'arc-diameter', type: 'diameter', label: msg('Diameter')},
									{component: 'arc-duration', type: 'duration', label: msg('Duration')},
									{component: 'arc-weight', type: 'weight', label: msg('Weight')},
								]}
								.data=${this.localFormData.measurements}
								.errors=${this.errors?.params?.measurements.params}
								@value-change=${(e) => this.handleMeasurementChange(e.detail.value)}
							>
							</arc-form-repeat>
						</arc-collapsible-section>

					</div>
					<div class="rightColumn"></div>
				</section>


				<section>
					<div class="leftColumn">
								<arc-collapsible-section title="${msg('Provenance')}">
										<arc-form-grid
											class="formGrid historyAttributes"
											.fields=${historyInputFields}
											.localFormData=${this.localFormData}
											.errors=${this.errors}
											.edit=${this.edit}
										>
										</arc-form-grid>
								</arc-collapsible-section>
					</div>
					<div class="rightColumn"></div>
				</section>

				<section>
					<div class="leftColumn">
							<arc-collapsible-section title="${msg('Miscellaneous')}">
								<arc-form-grid
									class="formGrid miscAttributes"
									.fields=${miscInputFields}
									.localFormData=${this.localFormData}
									.errors=${this.errors}
									.edit=${this.edit}
								>
								</arc-form-grid>
							</arc-collapsible-section>
					</div>
					<div class="rightColumn"></div>
				</section>

				<section>
					<div class="leftColumn">
						<h2 class="sectionTitle">${msg('Inventory Numbers')}</h2>

						<div class="keyValueList">
							<div class="keyValue">
								<div class="key">${msg('Kunstfonds Inventory Number')}</div>
								<div class="value">${this.localFormData.inventoryNumber}</div>
							</div>
						</div>

						<arc-form-repeat
							.components=${[
								{
									component: 'arc-inventory-number',
									type: null,
									label: msg('Inventory Number'),
								},
							]}
							.data=${this.localFormData.inventoryNumbers}
							.errors=${this.errors?.params?.inventoryNumbers.params}
							@value-change=${(e) => this.handleInventoryNumbersChange(e.detail.value)}
						></arc-form-repeat>

					</div>
					<div class="rightColumn"></div>
				</section>


				${when(
					isParentArtwork,
					() => html`
						<section>
							<div class="fullColumn">
								<h2 class="sectionTitle">${msg('Sub Artworks')}</h2>
								${repeat(
									this.childArtworks,
									(artwork) => artwork.id,
									(artwork) => html`
										<arc-artwork-list-item
											.artwork=${artwork}
											.currentLanguage=${this.currentLanguage}
										></arc-artwork-list-item>
									`
								)}
								<arc-button
									title="${msg('Add Child Artwork')}"
									type="primary"
									.additionalInfo="${false}"
									class="saveProfileButton"
									showIcon="true"
									@click=${this.handleAddChildArtwork}
								>
									${plus}
								</arc-button>
							</div>
						</section>
					`
				)}
			</div>
 <arc-confirmation-dialog></arc-confirmation-dialog>
			</div>
    `;
	}
}
