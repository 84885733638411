import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import agent from '../../arc/agent/agent';

const accountInitialState = {
	status: 'idle',
	data: {
		loggedIn: false,
		errors: null,
		api: {
			csrf: null,
			sid: null,
			version: null,
			time: null
		},
		account: {
			id: 'userid',
			email: null,
			name: null,
			preferredLanguage: ['de']
		},
		devLoginToken: null,
		loginEmail: null,
		profiles: []
	},
	error: {}
};

// server api calls actions
export const initSession = createAsyncThunk(
	'account/initSession',
	(options, {getState}) =>
		agent
			.get(`${getState().status.apiUrl}/init`)
			.set('Accept', 'application/json')
			.then((response) => ({body: response.body, headers: response.headers}))
			.catch((error) => error)
);

export const fetchAccount = createAsyncThunk(
	'account/fetchAccount',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account`)
			.send({account: getState().account.data.account})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const storeAccount = createAsyncThunk(
	'account/storeAccount',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account`)
			.send({account: getState().account.data.account})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const loginWithEmail = createAsyncThunk(
	'account/loginWithEmail',
	(options, {getState}) => {
		Cookies.set('arc-email', options.email, {expires: 99999});
		return agent
			.post(`${getState().status.apiUrl}/account/newLoginToken`)
			.send({email: options.email})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error);
	}
);

export const authenticateWithToken = createAsyncThunk(
	'account/authenticateWithToken',
	async (options, {dispatch, getState}) => {
		const loginResponse = await agent
			.post(`${getState().status.apiUrl}/account/login`)
			.send({
				email: options.email,
				token: options.token,
				consent: options.consent,
				longSession: options.longSession
			})
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => {
				throw error;
			});
		return loginResponse;
	}
);


export const logout = createAsyncThunk(
	'account/logout',
	(options, {getState}) =>
		agent
			.post(`${getState().status.apiUrl}/account/logout`)
			.set('Accept', 'application/json')
			.then((response) => response.body)
			.catch((error) => error)
);

export const accountSlice = createSlice({
	name: 'account',
	initialState: accountInitialState,

	// standard actions: for example update data
	// export actions at end of file
	reducers: {
		setLoginEmail: (state, action) => {
			state.data.loginEmail = action.payload.loginEmail;
		},
		setPreferredLanguage: (state, action) => {
			state.data.preferredLanguage = action.payload.value;
		}
	},

	// async actions: server api calls workflows
	extraReducers: (builder) => {

		builder.addCase(initSession.fulfilled, (state, action) => {
			state.status = 'idle';
			state.data.api.csrf = action.payload.headers['arc-csrf-token'];
			state.data.api.sid = action.payload.headers['arc-sid'];
			state.data.api.version = action.payload.headers['arc-api-version'];
			state.data.api.time = action.payload.headers['arc-api-time'];
			if (action.payload.body.account !== null) {
				state.data.account = action.payload.body.account;
				state.data.loggedIn = true;
			} else {
				state.data.loggedIn = false;
			}
			state.data.account = action.payload.body.account;
			state.error.message = null;
		});
		builder.addCase(initSession.pending, (state, action) => {
			state.status = 'pending';
			state.data.api.csrf = null;
			state.data.api.csrf = null;
			state.data.api.version = null;
			state.data.api.time = null;
			state.error.message = null;
		});
		builder.addCase(initSession.rejected, (state, action) => {
			state.status = 'error';
			state.data.api.csrf = null;
			state.data.api.csrf = null;
			state.data.api.version = null;
			state.data.api.time = null;
			state.error.message = 'error';
		});

		builder.addCase(storeAccount.fulfilled, (state, action) => {
			state.status = 'idle';
			state.error.message = null;
		});
		builder.addCase(storeAccount.pending, (state, action) => {
			state.status = 'saving';
			state.error.message = null;
		});
		builder.addCase(storeAccount.rejected, (state, action) => {
			state.status = 'error';
			state.error.message = 'error';
		});

		builder.addCase(loginWithEmail.fulfilled, (state, action) => {
			if (action.payload.loginToken) {
				// state.data.devLoginToken = action.payload.loginToken;
				state.data.loginEmail = action.meta.arg.email;
				state.data.loggedIn = false;
			} else {
				state.data.devLoginToken = null;
				state.data.loggedIn = false;
			}
			state.status = 'idle';
		});
		builder.addCase(loginWithEmail.pending, (state, action) => {
			state.status = 'loading';
			state.error = {};
		});
		builder.addCase(loginWithEmail.rejected, (state, action) => {
			state = {...accountInitialState};
			state.status = 'idle';
			state.error = action.payload;
		});

		builder.addCase(authenticateWithToken.fulfilled, (state, action) => {
			if (action.payload && action.payload.status === 422) {
				// request ok but login failed
				state.data.account = {...accountInitialState.data.account}; // reset account and
				state.data.errors = action.payload.response.body; // save error info for display in frontend

				state.data.loggedIn = false;
				state.data.loginFailed = true;
			} else if (action.payload && action.payload.account) {
				// login successful
				state.data.account = action.payload.account; // save account in state
				state.data.loggedIn = true;
				state.data.loginFailed = false;
			} else {
				// request failed
				state.data.account = {...accountInitialState.data.account};
				state.data.loggedIn = false;
				state.data.loginFailed = true;
			}
			state.status = 'idle';
		});
		builder.addCase(authenticateWithToken.pending, (state, action) => {
			console.log('authenticateWithToken.pending', action.payload);
			state.status = 'loading';
			state.error = {};
		});
		builder.addCase(authenticateWithToken.rejected, (state, action) => {
			console.log('authenticateWithToken.rejected', action.payload);
			state.data.loggedIn = false;
			state.status = 'idle';
			state.error = action.payload;
		});

		builder.addCase(logout.fulfilled, (state, action) => {
			state.data.loggedIn = false;
			state.data.account = {...accountInitialState.data.account};
			state.data.devLoginToken = null;
			state.status = 'idle';
			state.error = {};
		});
		builder.addCase(logout.pending, (state, action) => {
			state.status = 'loading';
			state.error = {};
		});
		builder.addCase(logout.rejected, (state, action) => {
			state.status = 'idle';
			state.error = action.payload;
		});
	}
});


export const {
	setLoginEmail,
	setPreferredLanguage
} = accountSlice.actions;


export default accountSlice.reducer;
