import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {customElement} from 'lit/decorators/custom-element.js';
import {msg} from '@lit/localize';
import {when} from 'lit/directives/when.js';

import store from '../../store';

import {logout} from '../../slices/accountSlice';

import style from '../../../../scss/accountView.scss';
import keyValueListStyle from '../../../../scss/keyValueList.scss';

@customElement('arc-account-view')
export class AccountView extends connect(store)(LitElement) {
	static styles = [keyValueListStyle, style];

	constructor() {
		super();
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.status = state.status;
		this.accountData = state.account.data.account;
		this.accountStatus = state.account.status;
		this.loggedIn = state.account.data.loggedIn;
	}

	async connectedCallback() {
		super.connectedCallback();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
	}

	onLogout(input) {
		store.dispatch(logout());
	}

	render() {
		const state = store.getState();
		return html`
			<div class="account-view">
				<h1>${msg('Account')}</h1>
				${when(
					this.loggedIn,
					() => html`
						<div class="keyValueList">
							<div class="keyValue">
								<div class="key">${msg('Name')}</div>
								<div class="value">${this.accountData.name}</div>
							</div>
							<div class="keyValue">
								<div class="key">${msg('Email')}</div>
								<div class="value">${this.accountData.email}</div>
							</div>
						</div>
						<arc-button
							@click=${this.onLogout}
							title="${msg('Logout')}"
							type="primary"
							.additionalInfo="${false}"
						>
						</arc-button>

						<arc-profile-list></arc-profile-list>

					`,
					() => html`
						<arc-routerlink class="requestToken" route="requestToken">${msg('Request Token')}</arc-routerlink>
					`
				)}
			</div>
		`;
	}
}
