import {connect} from 'pwa-helpers';
import {LitElement, html} from 'lit';
import {navigator, router} from 'lit-element-router';
import {customElement} from 'lit/decorators.js';
import {repeat} from 'lit/directives/repeat.js';
import {msg} from '@lit/localize';
import store from '../../store';
import {fetchArtworks, selectAllArtwork} from '../../slices/artworkSlice';
import {
	selectArtworkQuery,
	selectCurrentProfileDefaultLanguage,
	selectLanguage,
} from '../../selectors';
import style from '../../../../scss/artworkList.scss';
import artworks from '../../../../scss/artworks.scss';
import getRoutes from '../../routes';
import arrowRight from '../../icons/arrowRight';
import down from '../../icons/down';
import up from '../../icons/up';
import arrowDown from '../../icons/arrowDown';
import arrowUp from '../../icons/arrowUp';

@customElement('arc-artworks-bin')
export class ArtworskBin extends connect(store)(router(navigator(LitElement))) {
	static styles = [style, artworks];

	static properties = {
		artworks: {type: Array},
	};

	constructor() {
		super();
		this.artworks = [];
		store.subscribe(() => this.requestUpdate());
	}

	stateChanged(state) {
		this.state = state;
		this.status = state.status;
		this.lang = selectLanguage(state);
		this.artworkQuery = selectArtworkQuery(state);
		this.artworks = selectAllArtwork(state).filter((artwork) => artwork.isDeleted);
		this.totalPages = state.artwork.artworkPagination.pagesAmount;
		this.currentLanguage = selectCurrentProfileDefaultLanguage(state);
		this.requestUpdate();
	}

	static get routes() {
		return getRoutes();
	}

	router(route, params, query, data) {
		this.params = params;
		this.query = query;
		this.data = data;
		this.profileId = params.id;

		if (route === 'artworks-bin') {
			store.dispatch(
				fetchArtworks({
					profileId: this.profileId,
					isDeleted: true,
					...this.query,
				})
			);
		}
	}

	connectedCallback() {
		super.connectedCallback();
		const state = store.getState();
		const profileId = state.account.data.account?.mainProfile?.id;
		store.dispatch(fetchArtworks({profileId: profileId, isDeleted: true}));
		this.requestUpdate();
	}

	getOrderQuery(orderBy) {
		const orderByDirection = this.query?.orderByDirection || 'asc';
		const newQuery = {
			...this.query,
			orderBy: orderBy,
			orderByDirection: orderByDirection === 'desc' ? 'asc' : 'desc',
		};
		return newQuery;
	}

	getOrderDirectionIcon(orderBy) {
		if (this.query && this.query.orderBy === orderBy) {
			return this.query.orderByDirection === 'asc' ? down : up;
		} else {
			return '';
		}
	}

	render() {
		return html`
			<div class="artworks bin">
				<arc-toolbar>
					<div class="leftSlot" slot="left">
						<arc-breadcrumbs>
							<arc-routerlink route="artworks">${msg('Artworks')}</arc-routerlink>
						</arc-breadcrumbs>
					</div>
					<div class="rightSlot" slot="right"></div>
				</arc-toolbar>
				<h2>${msg('Deleted Artworks')}</h2>

				${this.artworks.length > 0
					? html`
							<div class="artworkListHeader">
								<div></div>
								<div>
									<arc-routerlink
										.route="artworks-bin"
										.query=${this.getOrderQuery('inventoryNumber')}
									>
										${msg('Nr.')} ${this.getOrderDirectionIcon('inventoryNumber')}
									</arc-routerlink>
								</div>
								<div>${msg('Title')}</div>
								<div>${msg('Materials')}</div>
								<div>
									<arc-routerlink .route="artworks-bin" .query=${this.getOrderQuery('artworkDate')}>
										${msg('Year')} ${this.getOrderDirectionIcon('artworkDate')}
									</arc-routerlink>
								</div>
								<div>
									<arc-routerlink .route="artworks-bin" .query=${this.getOrderQuery('status')}>
										${msg('Status')} ${this.getOrderDirectionIcon('status')}
									</arc-routerlink>
								</div>
								<div>
									<arc-routerlink .route="artworks-bin" .query=${this.getOrderQuery('visibility')}>
										${msg('Visibility')} ${this.getOrderDirectionIcon('visibility')}
									</arc-routerlink>
								</div>
							</div>

							<div class="artworkList">
								${repeat(
									this.artworks,
									(artwork) => artwork.id,
									(artwork, index) => html`
										<div class="artworkListItem">
											<arc-artwork-list-item
												.artwork=${artwork}
												.currentLanguage=${this.currentLanguage}
												.customClass=${this.contextClass}
											></arc-artwork-list-item>
										</div>
									`
								)}
							</div>
					  `
					: html` <p class="emptyMessage">${msg('No deleted artworks found.')}</p> `}
			</div>
		`;
	}
}
