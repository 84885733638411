import {LitElement, html, css} from 'lit';
import {customElement} from 'lit/decorators.js';
import {connect} from 'pwa-helpers/connect-mixin.js';
import {msg} from '@lit/localize';
import store from '../../store';
import style from '../../../../scss/confirmationDialog.scss';
import close from '../../icons/close';

@customElement('arc-confirmation-dialog')
export class ConfirmationDialog extends connect(store)(LitElement) {
	static styles = [style];
	static properties = {
		open: {type: Boolean},
		message: {type: String},
		warningMessage: {type: String},
		showConfirmButton: {type: Boolean},
		title: {type: String},
		buttonText: {type: String},
	};

	constructor() {
		super();
		this.open = false;
		this.message = '';
		this.warningMessage = '';
		this.title = '';
		this.showConfirmButton = true;
	}

	stateChanged(state) {
		this.open = state.artwork.dialog.open;
		this.message = state.artwork.dialog.message;
		this.warningMessage = state.artwork.dialog.warningMessage;
		this.title = state.artwork.dialog.title;
		this.buttonText = state.artwork.dialog.buttonText;

		if (this.open) {
			this.addEventListeners();
		} else {
			this.removeEventListeners();
		}
	}

	_confirm() {
		this.dispatchEvent(new CustomEvent('confirm', {bubbles: true, composed: true}));
		this._close();
	}

	_cancel() {
		this.dispatchEvent(new CustomEvent('cancel', {bubbles: true, composed: true}));
		this._close();
	}

	_close() {
		store.dispatch({type: 'artwork/closeDialog'});
	}

	handleModalClick(event) {
		if (event.target.classList.contains('modal') && this.open) {
			this._cancel();
		}
	}

	handleContentClick(event) {
		event.stopPropagation();
	}

	addEventListeners() {
		window.addEventListener('keydown', this.handleKeyDown);
	}

	removeEventListeners() {
		window.removeEventListener('keydown', this.handleKeyDown);
	}

	handleKeyDown = (event) => {
		if (event.key === 'Escape') {
			this._cancel();
		}
	};

	render() {
		return html`
			<div class="modal ${this.open ? 'open' : ''}" @click=${this.handleModalClick}>
				<div class="modal-content" @click=${this.handleContentClick}>
					<h2 class="dialogTitle">${this.title}</h2>
					<p class="warningMessage">${this.warningMessage}</p>
					<p class="dialogMessage">${this.message}</p>
					<div class="dialogButtons">
						<arc-button
							@click=${this._cancel}
							type="secondary"
							title="${this.showConfirmButton ? 'Cancel' : 'OK'}"
						></arc-button>
						${this.showConfirmButton
							? html`<arc-button
									@click=${this._confirm}
									type="primary"
									title="${this.title}"
							  ></arc-button>`
							: ''}
					</div>
					<button class="closeIcon" @click=${this._close} aria-label="${msg('Close')}">
						${close}
					</button>
				</div>
			</div>
		`;
	}
}
